/**
 * 여러장의 이미지를 하나의 캔버스에 그리기 위한 함수
 * @param src
 * @returns {Promise<unknown>}
 */
export const getImageDimensions = src => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
    img.src = src;
  });
};

/**
 * 캔버스위에 이미지를 그리기 위해 이미지 원본 소스로 부터 이미지 파일을 불러오는 함수
 * @param src
 * @returns {Promise<unknown>}
 */
export const loadImage = src => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = src;
    img.crossOrigin = "Anonymous"; // CORS 문제 완화
  });
};
