import Title01 from "../common/Title01";
import Link from "next/link";
import styled from "styled-components";
import styles from "../Main.module.css";
import { useTranslation } from "next-i18next";
export default function FooterLink() {
  const {
    t
  } = useTranslation("common");
  const dataList = [{
    url: "https://airs.kbcard.com/air/b2c/AIR/MBL/AIRMBLTRP0100100010.k1?KSESID=air:b2c:SELK138AU:SELK138AU:TRP001:00#none",
    title: t("main.title.airline.ticket")
  }, {
    url: "/kayak",
    title: t("main.title.rentcar")
  }, {
    url: "https://www.eastpak.co.kr/product/list.html?cate_no=443",
    title: t("main.title.tour.bag")
  }];
  return <section className={`${styles.mGroup12} mGroup12 relative mt-[208px] mb-[120px] tablet:mb-[56px] mobile:mt-[104px] mobile:mb-[58px] mobile:pr-[24px] mobile:pl-[8px]`}>
      <Title01>{t("main.title.tour.ready")}</Title01>
      <ul className="my-0 mx-auto flex max-w-[1180px] tablet:my-0 tablet:w-[initial] mobile:w-auto">
        {dataList.map((info, index) => {
        const background = ["before:bg-[url('/images/main/img_airline_g80.svg')]", "before:bg-[url('/images/main/img_rentcar_g80.svg')]", "before:bg-[url('/images/main/img_luggage_g80.svg')]"];
        return <li key={index} className={`h-[132px] w-[calc((100%)_/_3)] rounded-[12px] bg-Bg-TintWhite tablet:h-[124px] 
                        ${index > 0 ? "ml-[20px] tablet:ml-[16px]" : ""}
                        mobile:ml-[16px] mobile:h-auto mobile:w-[calc(100%-16px)] mobile:rounded-[8px]`}>
              <Link href={info.url} target={"_blank"} className={`H6_18_100_Bold mobile:C_12_100_Medium relative flex h-[132px] items-center justify-center
                         text-Gray-800 before:mr-[19px] before:inline-block before:h-[52px] before:w-[52px] before:bg-[0_0] before:bg-no-repeat
                         before:content-['']
                         tablet:h-[124px] mobile:h-[98px] mobile:flex-col
                         mobile:before:mr-0 mobile:before:mb-[5px] mobile:before:h-[40px] mobile:before:w-[40px] ${background[index]} mobile:before:bg-cover`}>
                {info.title}
              </Link>
            </li>;
      })}
      </ul>
    </section>;
}