import { getAccessToken } from "@modules/utils/AccessToken";
import { ConvertLocale } from "@modules/utils/ConvertLocale";
import { getCookie } from "cookies-next";

export async function getCountryFromIP(locale) {
  const res = await fetch("/api/region/ip2country", {
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
      "X-UA-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      "X-UA-Country-Code": getCookie("country_code") || locale?.split("-")[1],
    },
  });
  return { status: res.status, body: await res.json() };
}
