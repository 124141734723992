import { useState, useRef, useEffect } from "react";
const DevRemoteController = ({
  contents,
  callbacks,
  width,
  height
}) => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0
  });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({
    x: 0,
    y: 0
  });
  const popupRef = useRef(null);
  const handleMouseDown = e => {
    setIsDragging(true);
    setOffset({
      x: e.pageX - popupRef.current.offsetLeft,
      y: e.pageY - popupRef.current.offsetTop
    });
  };
  const handleTouchStart = e => {
    setIsDragging(true);
    setOffset({
      x: e.touches[0].pageX - popupRef.current.offsetLeft,
      y: e.touches[0].pageY - popupRef.current.offsetTop
    });
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleTouchEnd = () => {
    setIsDragging(false);
  };
  const handleMouseMove = e => {
    if (isDragging) {
      // offset 값을 빼서 위치 계산
      const newX = e.pageX - offset.x;
      const newY = e.pageY - offset.y;
      setPosition({
        x: newX,
        y: newY
      });
    }
  };
  const handleTouchMove = e => {
    if (isDragging) {
      // offset 값을 빼서 위치 계산
      const newX = e.touches[0].pageX - offset.x;
      const newY = e.touches[0].pageY - offset.y;
      setPosition({
        x: newX,
        y: newY
      });
    }
  };
  useEffect(() => {
    const handleScroll = e => {
      if (isDragging) {
        e.preventDefault();
      }
    };
    const handleTouchMoveIOS = e => {
      if (isDragging) {
        e.preventDefault();
      }
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleTouchMove, {
      passive: false
    });
    document.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("scroll", handleScroll);

    // iOS에서는 별도의 이벤트를 처리해야 함
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      document.addEventListener("touchmove", handleTouchMoveIOS, {
        passive: false
      });
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("scroll", handleScroll);
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        document.removeEventListener("touchmove", handleTouchMoveIOS, {
          passive: false
        });
      }
    };
  }, [isDragging]);
  useEffect(() => {
    // 팝업이 열려 있는 동안에만 터치 스크롤을 막기
    if (isDragging) {
      window.ontouchmove = e => {
        e.preventDefault();
      };
    } else {
      window.ontouchmove = null;
    }
  }, [isDragging]);
  return process.env.NEXT_PUBLIC_DEV_ENV === "development" ? <div ref={popupRef} style={{
    position: "fixed",
    top: `${position.y}px`,
    left: `${position.x}px`,
    width: width || "120px",
    height: height || "180px",
    backgroundColor: "white",
    borderRadius: "16px",
    border: "1px solid black",
    zIndex: 9999,
    cursor: isDragging ? "grabbing" : "grab"
  }} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
      <p className="mt-2.5 text-center">{"테스트용 RC"}</p>
      <p className="mb-2.5 text-center text-[10px]">{"(드래그 가능)"}</p>
      {contents}
      <p className="mt-2 cursor-pointer text-center text-[14px]" onClick={() => {
      callbacks("close");
    }}>
        {"닫기"}
      </p>
    </div> : <></>;
};
export default DevRemoteController;