import BulletEm from "../common/BulletEm";
import Link from "next/link";
import { Commify } from "@modules/utils/Commify";
import { useTranslation } from "next-i18next";
import { Contains } from "@constants/contains";
import { useRouter } from "next/router";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import ImageFallback from "@components/common/image";
import dayjs from "dayjs";
export default function SlideCard({
  item
}) {
  const {
    locale
  } = useRouter();
  const {
    t
  } = useTranslation("common");
  return <Link href={{
    pathname: "/hotels/[id]",
    query: {
      id: item.hotel_id,
      "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(getSearchRoomsCookie()),
      query: item.hotel_name,
      searchId: item.hotel_id,
      searchType: Contains.HOTEL
    }
  }} className="block">
      <div className="img relative overflow-hidden rounded-[12px] pb-[73.3%] tablet:h-[205px] tablet:pb-0 mobile:h-[154px]">
        <ImageFallback fill sizes="(max-width: 768px) 154px, 280px" className="absolute left-0 top-0 h-full w-full object-cover" src={item.thumbnail_url} alt="" />
      </div>
      <div className="cnt whitespace-nowrap pt-[14px] text-left mobile:mr-[8px]">
        <span className="C_12_100_Medium block text-Gray-400 ">
          {item.star_rating}
          {t("search.txt.star")}
          <BulletEm>{item.region_name}</BulletEm>
        </span>
        <p className="B_16_145_Medium line-clamp-2 overflow-ellipsis whitespace-normal pt-[4px] text-Gray-800">
          {item.hotel_name}
        </p>
        <div className="price H4_24_100_Bold mobile:H5_20_100_Bold pt-[8px]">
          {locale === "en-US" && "$"}
          {Commify(item.price)}
          <sub className="SB_14_100_Regular mobile:C_12_100_Medium relative top-[1px] inline-block p-0 pl-[4px] align-middle text-Gray-900">
            {locale === "ko-KR" && "원~"}
            {locale === "en-US" && "~"}
          </sub>
        </div>
      </div>
    </Link>;
}