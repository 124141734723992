import Image from "next/image";
import React, { useState } from "react";
const EventBanner = ({
  data
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const formatSubtitle = (sub_title, promotion_code) => {
    // 모든 \n 문자를 <br /> 태그로 치환
    sub_title = sub_title.replace(/\n/g, "<br />");

    // promotion_code와 일치하는 모든 문자열을 <span> 태그로 치환
    const regex = new RegExp(promotion_code, "g");
    sub_title = sub_title.replace(regex, `<span class="underline font-bold">${promotion_code}</span>`);
    return sub_title;
  };
  const subTitleHtml = formatSubtitle(data?.sub_title || "", data?.promotion_code);
  return <>
      {<>
          <div className="flex h-[50px] w-full flex-row items-center justify-center bg-black">
            <Image src="/images/common/img_tripbtoz_symbol.svg" alt="tripbtoz_logo" width={30} height={30} />
            <p className="P_15_160_Bold ml-2 mr-3 text-white">{data?.title}</p>
            <div className="flex h-8 w-8 cursor-pointer items-center" onClick={() => {
          setIsExpanded(!isExpanded);
        }}>
              {isExpanded ? <Image src="/images/common/ic_arrow_up_ou_g90.png" alt="arrow_down_icon" width={16} height={16} /> : <Image src="/images/common/ic_arrow_down_ou_wh.png" alt="arrow_down_icon" width={16} height={16} />}
            </div>
          </div>
          {isExpanded && <div className="flex w-full flex-col items-center bg-black pb-6">
              <Image src={data.image_url} alt="QRCode" width={84 * 2} height={84 * 2} quality={100} className="mt-5 h-[84px] w-[84px] rounded-lg mobile:hidden" />
              <div className="mt-3 line-clamp-2 text-center text-white" dangerouslySetInnerHTML={{
          __html: subTitleHtml
        }}></div>
              <div className="SB_14_100_Bold mt-4 hidden cursor-pointer rounded-[50px] bg-white px-4 py-[9px] text-Gray-800 tablet:hidden mobile:block" onClick={() => {
          if (data?.one_link || data?.web_link) {
            if (window) window.open(data?.one_link || data?.web_link);
          }
        }}>
                {"앱으로 보기"}
              </div>
            </div>}
        </>}
    </>;
};
export default EventBanner;