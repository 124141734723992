import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from "swiper";
import styled from "styled-components";
import Title01 from "../common/Title01";
import Link from "next/link";
import styles from "../Main.module.css";
import dynamic from "next/dynamic";
import ImageFallback from "@components/common/image";
import { useRouter } from "next/router";
import { useTablet } from "@modules/hooks/useMedia";
import { gtagEvent } from "@modules/lib/gtm";
const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false
});
export default function BannerB({
  info,
  isKb = false
}) {
  const router = useRouter();
  const isTablet = useTablet();
  const linkHandler = () => {
    router.push(process.env.NEXT_PUBLIC_TTBB_PLAN_URL);
  };
  let imgUrl = "";
  const infoItems = info && info?.items?.map(item => {
    if (isTablet) {
      imgUrl = item["thumbnail_url"];
    } else {
      imgUrl = item["main_image_url"];
    }
  });
  return <>
      {isKb && <div className={`${styles.mGroup4} mGroup4 mb-[128px] pb-[60px] pt-[40px] mobile:mb-[40px] mobile:pb-[33px] mobile:pt-[20px]`} onClick={linkHandler}>
          <img src={imgUrl} alt={`배너`} className={"mx-auto h-[160px] w-[1180px] cursor-pointer rounded-xl tablet:h-full tablet:w-full tablet:rounded-none"} />
        </div>}

      {!isKb && <section className={`${styles.mGroup7} mGroup7 relative mb-[128px] overflow-hidden pb-[40px] pt-[40px] tablet:px-[24px] mobile:mb-[40px] mobile:px-[0] mobile:pb-[20px] mobile:pt-[20px]`}>
          {info.title && <Title01>{info.title}</Title01>}
          {info.items && <>
              <MediaQuery minWidth={768}>
                <SwiperWrapper>
                  <Swiper modules={[Navigation, Pagination, FreeMode]} slidesPerView={4} slidesPerGroup={4} loopFillGroupWithBlank={true} freeMode={{
              enabled: true,
              momentumVelocityRatio: 1.3
            }} spaceBetween={18} autoHeight={true} pagination={{
              el: ".mGroup7 .swiper-pagination"
            }} navigation={{
              prevEl: ".mGroup7 .swiper-button-prev",
              nextEl: ".mGroup7 .swiper-button-next"
            }} breakpoints={{
              0: {
                slidesPerView: "auto"
              },
              360: {
                slidesPerView: "auto"
              },
              768: {
                spaceBetween: 16,
                freeMode: false,
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              1024: {
                spaceBetween: 16,
                freeMode: false,
                slidesPerView: 4,
                slidesPerGroup: 4
              }
            }} onTouchStart={() => {
              gtagEvent("sliderSwipe_main_bannerB", {});
            }}>
                    {info.items?.map((item, index) => <SwiperSlide key={index}>
                        <Link href={item.web_link}>
                          <div className="img relative overflow-hidden pb-[128%] mobile:h-[188px] mobile:pb-0">
                            <ImageFallback fill sizes="25vw" quality={90} className="absolute left-0 top-0 w-full mobile:object-contain" src={item.thumbnail_url} alt="" />
                          </div>
                        </Link>
                      </SwiperSlide>)}
                  </Swiper>
                  <div className="swiper-pagination"></div>
                  <div className={`${styles.swiperButtonPrev} swiper-button-prev !left-1/2 !top-[290px] ml-[-691px] h-[48px] w-[48px] rotate-180 bg-ic_arrow_right_ou_b_g90 
            bg-[length:48px_48px] bg-center bg-no-repeat !text-black after:!text-[0] tablet:!hidden`} />
                  <div className={`${styles.swiperButtonNext} swiper-button-next !right-1/2 !top-[290px] mr-[-691px] h-[48px] w-[48px] bg-ic_arrow_right_ou_b_g90 bg-[length:48px_48px] 
            bg-center bg-no-repeat !text-black after:!text-[0] tablet:!hidden`} />
                </SwiperWrapper>
              </MediaQuery>
              <MediaQuery maxWidth={767}>
                <ListTumb className="mx-[24px] tablet:my-0
                         mobile:m-0 mobile:overflow-x-auto mobile:overflow-y-hidden mobile:whitespace-nowrap mobile:scrollbar-hide" onTouchStart={() => {
            gtagEvent("sliderSwipe_main_bannerB", {});
          }}>
                  <ul className="m-[-22px_auto_0] flex max-w-[1180px] gap-[16px] tablet:m-0 tablet:w-auto mobile:mx-[16px] mobile:my-0 mobile:inline-flex">
                    {info.items?.map((item, index) => <li key={index} className="tablet:w-[148px]">
                        <Link href={item.web_link}>
                          <div className="img relative overflow-hidden pb-[134%] mobile:h-[188px] mobile:pb-0">
                            <ImageFallback fill sizes="150px" className="absolute left-0 top-0 w-full mobile:object-contain" src={item.thumbnail_url} alt="" />
                          </div>
                        </Link>
                      </li>)}
                  </ul>
                </ListTumb>
              </MediaQuery>
            </>}
        </section>}
    </>;
}
const SwiperWrapper = styled.div.withConfig({
  displayName: "BannerB__SwiperWrapper",
  componentId: "sc-10u79bx-0"
})([".swiper{", " .swiper-slide{", "}}.swiper-pagination{", " .swiper-pagination-bullet{", "}.swiper-pagination-bullet:first-child{", "}.swiper-pagination-bullet-active{", "}}.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{", "}"], {
  "margin": "7px auto 0",
  "maxWidth": "1180px",
  "@media (max-width: 1024px)": {
    "margin": "0",
    "width": "auto"
  },
  "@media (max-width: 767px)": {
    "margin": "0px",
    "paddingLeft": "16px",
    "paddingRight": "16px"
  }
}, {
  "@media (max-width: 767px)": {
    "width": "148px !important"
  }
}, {
  "position": "relative",
  "bottom": "unset",
  "width": "100%",
  "paddingTop": "21px",
  "@media (max-width: 767px)": {
    "display": "none",
    "paddingTop": "7px"
  }
}, {
  "margin": "0 0 0 8px",
  "height": "8px",
  "width": "8px",
  "borderRadius": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "opacity": "0.25",
  "@media (max-width: 767px)": {
    "height": "6px",
    "width": "6px"
  }
}, {
  "marginLeft": "0px"
}, {
  "opacity": "1"
}, {
  "opacity": ".12"
});
const ListTumb = styled.div.withConfig({
  displayName: "BannerB__ListTumb",
  componentId: "sc-10u79bx-1"
})(["::-webkit-scrollbar{", "}"], {
  "height": "0"
});