import { useDomain } from "@modules/hooks/useDomain";
import { useTablet } from "@modules/hooks/useMedia";
function SearchBtn({
  onClick,
  text = '',
  className = '',
  type = ''
}) {
  const isTTBB = useDomain();
  const isTablet = useTablet();
  const textColor = !isTTBB && 'text-white';
  const btnImgUrl = isTTBB ? `bg-[url('/images/ttbb/bg_btn_sch.svg')]` : `bg-[url('/images/main/bg_btn_sch.svg')]`;
  return <>
            <button onClick={onClick} className={`cursor-pointer absolute flex justify-center items-center
                ${type === 'main_search' && `btnSearch right-[8px] top-[8px] w-[120px] h-[56px] rounded-full text-center B_16_100_Bold 
                tablet:w-[48px] tablet:h-[48px] tablet:top-[3px] tablet:right-[5px] ${isTablet && btnImgUrl} tablet:bg-no-repeat tablet:bg-[center_right] 
                mobile:w-[40px] mobile:h-[40px] bg-Primary500 ${textColor} ${className}`}
                
                ${type === 'main_scrollUp' && `right-[4px] w-[40px] h-[40px] ${btnImgUrl}`}
                `}>
               <span className='tablet:hidden'>{text}</span>
            </button>

        </>;
}
export default SearchBtn;