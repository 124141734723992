import { useEffect, useRef, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import axios from "axios";
import CookieModal from "./CookieModal";
import { disableBodyScrollLock, enableBodyScrollLock } from "@modules/utils/BodyScrollLock";
import { getDomain } from "@modules/utils/AccessToken";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { getCountryFromIP } from "@modules/api/region";
export const cookies = {
  necessary: ["page_uid", "ch-session-58396", "user_session", "AWSALBCORS", "AWSELBCORS", "marketing_meta", "AEC", "tbuser", "x-veil-id", "ch-veil-id", "SIDCC", "__Secure-1PSIDCC", "__Secure-3PSIDCC", "__Secure-1PSID", "__Secure-3PSID", "HSID", "SSID", "APISID", "SAPISID", "__Secure-1PAPISID", "__Secure-3PAPISID", "sLogin", "Authorization", "campaign", "tbmeta"],
  functionality: [],
  analytics: ["_gid", "_ga", "_ga_RMHXMMRSBN", "_ga_2BN6KQLKYV", "_gat_gtag_UA_76437615_1", "OTZ"],
  advertising: ["DSID", "1P_JAR", "nx_ssl", "DSPRSYNC", "_gcl_au", "DSPR", "_fbp", "NID", "aid", "aid_ts", "SID", "webid", "webid_sync", "webid_ts", "_T_ANO", "IDE", "NNB", "fr", "DV"]
};
export default function Cookie() {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const [cookieConsent, setCookieConsent] = useState("true");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userCountry, setUserCountry] = useState("KR");
  const [domain, setDomain] = useState();
  const cookieConsentRef = useRef();
  const cookiesRef = useRef({
    necessary: cookies.necessary,
    functionality: cookies.functionality,
    analytics: cookies.analytics,
    advertising: cookies.advertising
  });
  const cookieDescription = t("setting.popup.terms.content");
  const handleAcceptCookie = () => {
    Cookies.set("accept_cookie", JSON.stringify(cookiesRef.current), {
      expires: 999,
      domain: getDomain()
    });
    setCookieConsent("true");
    disableBodyScrollLock();
  };
  const handleSettingCookie = () => {
    cookiesRef.current = {
      ...cookiesRef.current,
      functionality: [],
      analytics: [],
      advertising: []
    };
    setIsOpenModal(true);
    enableBodyScrollLock();
  };
  const handleClickOverlay = e => {
    e.target.classList.add("hidden");
    cookieConsentRef.current.accept();
    handleAcceptCookie();
  };
  const handleSubmit = () => {
    cookieConsentRef.current.accept();
    setIsOpenModal(false);
    handleAcceptCookie();
  };
  const getCountry = async () => {
    const data = await getCountryFromIP(locale);
    if (data) {
      if (data.body?.item === "KR") {
        handleAcceptCookie();
      } else {
        setUserCountry(data?.body?.item);
      }
    }
  };
  useEffect(() => {
    setDomain(getDomain());
    const cookieConsentValue = Cookies.get("CookieConsent");
    setCookieConsent(cookieConsentValue || "false");
    if (Cookies.get("accept_cookie")) return;
    getCountry();
  }, []);
  useEffect(() => {
    if (userCountry === "KR") {
      disableBodyScrollLock();
    } else if (cookieConsent !== "true") {
      enableBodyScrollLock();
    } else {
      disableBodyScrollLock();
    }
  }, [cookieConsent, userCountry]);
  if (userCountry === "KR") return;
  return <>
      {cookieConsent !== "true" && <div className="fixed left-0 top-0 z-[14] h-full w-full mobile:bg-DimBlack-7" onClick={handleClickOverlay}></div>}
      <CookieConsent enableDeclineButton ref={cookieConsentRef} disableStyles={true} setDeclineCookie={false} onAccept={handleAcceptCookie} onDecline={handleSettingCookie} extraCookieOptions={{
      domain: domain
    }} expires={999} containerClasses="fixed w-full shadow-[0px_-4px_24px_rgba(0,0,0,0.1)] z-[14] pt-0 pb-[32px] px-0 left-0 bottom-0 bg-white before:content-['']
                          mobile:px-[16px] mobile:pb-[16px] mobile:rounded-t-[20px]
                          mobile:before:absolute mobile:before:w-full mobile:before:h-[56px] mobile:before:rounded-[20px_20px_0_0] mobile:before:left-0 mobile:before:top-0 mobile:before:bg-white" contentClasses="inner !px-[24px] mobile:!px-0" buttonWrapperClasses="inner flex justify-end !pt-[24px] !px-[24px] mobile:flex-col mobile:!px-0 mobile:!pt-[12px] mobile:mt-[8px]" declineButtonClasses="flex B_16_100_Bold items-center justify-center w-[220px] h-[48px] border border-Gray-50 rounded-[8px] border-solid bg-white
                              mobile:w-full" buttonClasses="flex B_16_100_Bold items-center justify-center w-[220px] h-[48px] border border-Gray-50 rounded-[8px] border-solid text-white bg-black ml-[12px]
                       mobile:w-full mobile:ml-0 mobile:mt-[8px]" declineButtonText={t("setting.popup.terms.manage")} buttonText={t("setting.btn.terms.agree.all")}>
        <p className="B_16_160_Regular mobile:SB_14_160_Regular pt-[26px] text-Gray-900 mobile:pt-[56px]">
          {cookieDescription}
        </p>
      </CookieConsent>
      {isOpenModal && <CookieModal handleSubmit={handleSubmit} cookiesRef={cookiesRef} />}
    </>;
}