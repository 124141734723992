import { getValueByDomain } from "@modules/utils/getValuewithDomain";
export default function TabBtn({
  onClick,
  isSelected,
  text,
  className
}) {
  const tabBorder = getValueByDomain('border-Gray-100', 'border-0');
  const tabBg = getValueByDomain('', 'bg-Gray-100');
  const tabText = getValueByDomain('text-Gray-400', 'text-Gray-600');
  return <button onClick={onClick} className={`inline-flex items-center justify-center text-Gray-400 border border-solid ${tabBg} ${tabBorder} ${tabText}  rounded-full hover:text-white hover:bg-Gray-900 hover:border-Gray-900 
                 ${isSelected && '!border-Gray-900 !bg-Gray-900 !text-white font-bold'} ${className}
                `}>
          {text}
        </button>;
}