import Link from "next/link";
import { useTranslation } from "next-i18next";
import { Contains } from "@constants/contains";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import BulletEmCenter from "../common/BulletEmCenter";
import ImageFallback from "@components/common/image";
import dayjs from "dayjs";
import { useDomain } from "@modules/hooks/useDomain";
export default function SlideCard({
  item
}) {
  const {
    t
  } = useTranslation("common");
  const isTTBB = useDomain();
  return <Link href={{
    pathname: "/hotels/[id]",
    query: {
      id: item.hotel_id,
      "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(getSearchRoomsCookie()),
      query: item.hotel_name,
      searchId: item.hotel_id,
      searchType: Contains.HOTEL
    }
  }} className="block">
      <div className="img relative h-[250px] overflow-hidden rounded-[12px] mobile:h-[214px]">
        {/*<img*/}
        {/*  className="absolute left-0 top-0 h-full w-full object-cover"*/}
        {/*  src={item.thumbnail_url}*/}
        {/*  onError={e => {*/}
        {/*    e.target.src = "/images/common/img_default_3by4.png";*/}
        {/*  }}*/}
        {/*  alt=""*/}
        {/*/>*/}
        <ImageFallback className="absolute left-0 top-0 h-full w-full object-cover" src={item.thumbnail_url} fill priority loading="eager" sizes="(max-width: 768px) 90vw, 40vw" quality={90} placeholder="empty" />
      </div>
      <div className="cnt whitespace-nowrap pt-[20px] text-center mobile:mr-[8px] mobile:ml-[8px] mobile:pt-[14px]">
        <span className="SB_14_100_Medium tablet:C_12_100_Medium mobile:C_12_100_Medium block text-Gray-400">
          {item?.star_rating > 0 ? `${item?.star_rating}${t("search.txt.star")}` : ""}
          {item?.star_rating > 0 && ` · `}
          {item.region_name}
        </span>
        <p className="H6_18_100_Bold tablet:B_16_100_Bold mobile:B_16_100_Bold overflow-hidden overflow-ellipsis whitespace-nowrap pt-[8px] text-Gray-800">
          {item.hotel_name}
        </p>
        <p className={`desc SB_14_150_Regular mobile:SB_14_150_Regular pt-[12px] ${isTTBB ? "text-PositiveBlue" : "text-Primary600"}`}>
          {item.description}
        </p>
      </div>
    </Link>;
}