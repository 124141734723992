import Title01 from "../common/Title01";
import { Swiper, SwiperSlide } from "swiper/react";
import SlideCard from "./SlideCard";
import "swiper/css";
import styled from "styled-components";
import { useRef } from "react";
import { Navigation, Pagination } from "swiper";
import styles from "../Main.module.css";
import { gtagEvent } from "@modules/lib/gtm";
export default function ListD({
  info
}) {
  const slideRef = useRef();
  return <section ref={slideRef} className={`${styles.mGroup2} mGroup2 mb-[128px] pt-[16px] pb-[28px]
                  tablet:mb-[128px]
                  mobile:mb-[40px] mobile:pt-[20px] mobile:pb-[20px]`}>
      {info.title && <Title01>{info.title}</Title01>}
      {info.items && <SwiperWrapper>
          <Swiper modules={[Navigation, Pagination]} className="listTumb" slidesPerView="auto" spaceBetween={20} autoplay={{
        enable: false,
        disableOnInteraction: false
      }} pagination={{
        el: ".mGroup2 .swiper-pagination"
      }} navigation={{
        prevEl: ".mGroup2 .swiper-button-prev",
        nextEl: ".mGroup2 .swiper-button-next"
      }} loop={true} breakpoints={{
        360: {
          spaceBetween: 16,
          centeredSlides: true
        }
      }} onTouchStart={() => {
        gtagEvent("sliderSwipe_main_listD", {});
      }}>
            {info.items?.map((item, idx) => <SwiperSlide key={idx}>
                <SlideCard item={item} />
              </SwiperSlide>)}
          </Swiper>
          <div className={`${styles.swiperButtonPrev} swiper-button-next tablet:!hidden`}></div>
          <div className={`${styles.swiperButtonNext} swiper-button-prev tablet:!hidden`}></div>
        </SwiperWrapper>}
    </section>;
}
const SwiperWrapper = styled.div.withConfig({
  displayName: "ListD__SwiperWrapper",
  componentId: "sc-1f7okbx-0"
})(["", " .swiper-slide{", " .desc{display:none;}}.swiper-slide.swiper-slide-active{.desc{display:block;}}.swiper-button-next,.swiper-button-prev{", "}.swiper-button-next{", "}.swiper-button-next:after,.swiper-button-prev:after{", "}.swiper-button-prev{", "}"], {
  "position": "relative"
}, {
  "width": "380px",
  "@media (max-width: 1024px)": {
    "width": "353px"
  },
  "@media (max-width: 767px)": {
    "width": "312px"
  }
}, {
  "top": "73px",
  "marginTop": "0px",
  "height": "96px",
  "width": "96px",
  "backgroundImage": "url(\"/images/main/bg_btn_mGroup2_arr.svg\")",
  "backgroundPosition": "0 0",
  "backgroundRepeat": "no-repeat"
}, {
  "right": "234px",
  "marginRight": "0px",
  "--tw-rotate": "180deg",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}, {
  "fontSize": "0"
}, {
  "left": "234px",
  "marginLeft": "0px"
});