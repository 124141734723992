import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import styled from "styled-components";
import Link from "next/link";
import styles from "../Main.module.css";
import ImageFallback from "components/common/image";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { getImageDimensions, loadImage } from "@modules/utils/loadImage";
import { gtagEvent } from "@modules/lib/gtm";
export default function BannerC({
  info
}) {
  const [sideColor, setSideColor] = useState([]); // 이미지에서 추출한 색상을 저장할 상태
  const [swiperIndex, setSwiperIndex] = useState(0); // 현재 슬라이드중인 swiper 인덱스

  useEffect(() => {
    const processImages = async () => {
      const imageSources = info.items.map(v => v.main_image_url); // 이미지 소스만 묶은 배열 생성
      try {
        const {
          canvas,
          topPixelColors,
          bottomPixelColors
        } = await drawImagesAndGetFirstPixelColors(imageSources);
        setSideColor([topPixelColors, bottomPixelColors]);
      } catch (error) {
        console.error("Error processing images:", error);
      }
    };
    processImages(); // 이미지 처리 함수 실행
  }, [info]);

  /**
   * 이미지 소스 배열을 순회 하면서 맨 좌측 상단 픽셀과 좌측 하단 픽셀의 색상값을 뽑아 새로운 배열로 반환
   * @param imageSources
   * @returns {Promise<{bottomPixelColors: *[], canvas: HTMLCanvasElement, topPixelColors: *[]}>}
   */
  const drawImagesAndGetFirstPixelColors = async imageSources => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d", {
      willReadFrequently: true
    });
    let canvasWidth = 0;
    let canvasHeight = 0;
    const topPixelColors = [];
    const bottomPixelColors = [];
    for (const source of imageSources) {
      try {
        const {
          width,
          height
        } = await getImageDimensions(source);
        canvasWidth += width;
        if (height > canvasHeight) {
          canvasHeight = height;
        }
        const img = await loadImage(source);
        canvas.height = canvasHeight;
        context.drawImage(img, 0, 0);
        const imageDataTop = context.getImageData(0, 0, 1, 1);
        const imageDataBottom = context.getImageData(0, height - 1, 1, 1);
        const [r, g, b, a] = imageDataTop.data;
        const [rb, gb, bb, ab] = imageDataBottom.data;

        // 화살표 및 인디케이터 자동 명암처리를 위한 부분
        // SMPTE-274M 표준의 YPbPr 신호 수식중 Y값 계산식
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        const txtColor = luma < 127.5 ? "white" : "black";
        topPixelColors.push({
          r,
          g,
          b,
          a,
          txtColor
        });
        bottomPixelColors.push({
          r: rb,
          g: gb,
          b: bb,
          a: ab,
          txtColor
        });
      } catch (error) {
        console.error(`Failed to process image: ${error.message}`);
      }
    }
    return {
      canvas,
      topPixelColors,
      bottomPixelColors
    };
  };
  return <section className={`${styles.mGroup8} mGroup8 relative mb-[128px] overflow-hidden mobile:mb-[40px]`}>
      {info.items && <SwiperWrapper>
          <Swiper modules={[Navigation, Pagination, Autoplay]} autoplay={info.items?.length > 1 ? {
        delay: 3500
      } : false} loop={info.items?.length > 1} slidesPerView="auto" pagination={{
        // el: ".mGroup8 .swiper-pagination",
        enabled: true
      }} navigation={{
        prevEl: ".mGroup8 .swiper-button-prev",
        nextEl: ".mGroup8 .swiper-button-next"
      }} onSlideChange={swiper => {
        setSwiperIndex(swiper.realIndex);
      }} style={{
        "--swiper-pagination-color": `${sideColor[0]?.[swiperIndex].txtColor === "white" ? "#FFF" : "#000"}`,
        "--swiper-pagination-bullet-inactive-color": `${sideColor[0]?.[swiperIndex].txtColor === "white" ? "#FFF" : "#000"}`
      }} onTouchStart={() => {
        gtagEvent("sliderSwipe_main_bannerC", {});
      }}>
            {info.items.map((item, index) => <SwiperSlide key={index} className="bgVisualColor01">
                <Link href={item.web_link} className={`relative flex overflow-hidden ${isMobile ? "pb-[55%]" : "pb-[25%]"}`}
          // className={`relative flex max-h-[480px] flex-row overflow-hidden`}
          >
                  {/*<div*/}
                  {/*  className="w-full"*/}
                  {/*  style={{*/}
                  {/*    background: `linear-gradient(180deg, ${*/}
                  {/*      (sideColor[0]?.[index] &&*/}
                  {/*        `rgba(${sideColor[0]?.[index].r}, ${sideColor[0]?.[index].g}, ${sideColor[0]?.[index].b}, ${sideColor[0]?.[index].a})`) ||*/}
                  {/*      `rgba(255, 255, 255, 255)`*/}
                  {/*    } 0%, ${*/}
                  {/*      (sideColor[1]?.[index] &&*/}
                  {/*        `rgba(${sideColor[1]?.[index].r}, ${sideColor[1]?.[index].g}, ${sideColor[1]?.[index].b}, ${sideColor[1]?.[index].a})`) ||*/}
                  {/*      `rgba(255, 255, 255, 255)`*/}
                  {/*    } 100%)`,*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<div className="mx-auto h-full w-full max-w-[1180px] shrink-0">*/}
                  <ImageFallback className={isMobile ? `h-auto` : `absolute left-0 top-0 block h-auto w-full`}
            // className="h-auto object-cover"
            // width={1180}
            // height={480}
            fill src={isMobile ? item.thumbnail_url : item.main_image_url} placeholder="empty" quality={95} sizes="120vw" alt="bannerCImage" />
                  {/*</div>*/}
                  {/*<div*/}
                  {/*  className="w-full"*/}
                  {/*  style={{*/}
                  {/*    background: `linear-gradient(180deg, ${*/}
                  {/*      (sideColor[0]?.[index] &&*/}
                  {/*        `rgba(${sideColor[0]?.[index].r}, ${sideColor[0]?.[index].g}, ${sideColor[0]?.[index].b}, ${sideColor[0]?.[index].a})`) ||*/}
                  {/*      `rgba(255, 255, 255, 255)`*/}
                  {/*    } 0%, ${*/}
                  {/*      (sideColor[1]?.[index] &&*/}
                  {/*        `rgba(${sideColor[1]?.[index].r}, ${sideColor[1]?.[index].g}, ${sideColor[1]?.[index].b}, ${sideColor[1]?.[index].a})`) ||*/}
                  {/*      `rgba(255, 255, 255, 255)`*/}
                  {/*    } 100%)`,*/}
                  {/*  }}*/}
                  {/*/>*/}
                </Link>
              </SwiperSlide>)}
          </Swiper>
          <div className={`swiper-pagination`}></div>
          <div className={`${styles.swiperButtonPrev} swiper-button-prev !left-1/2 !mt-[-24px] ml-[-691px] !h-[48px] !w-[48px] rotate-180 ${sideColor[0]?.[swiperIndex].txtColor === "white" ? `bg-[url('/images/main/ic_arrow_right_ou_b_g90_white.svg')]` : "bg-ic_arrow_right_ou_b_g90"}  
            bg-[length:48px_48px] bg-center bg-no-repeat after:!text-[0] tablet:!hidden`} />
          <div className={`${styles.swiperButtonNext} swiper-button-next !right-1/2 !mt-[-24px] mr-[-691px] !h-[48px] !w-[48px] ${sideColor[0]?.[swiperIndex].txtColor === "white" ? `bg-[url('/images/main/ic_arrow_right_ou_b_g90_white.svg')]` : "bg-ic_arrow_right_ou_b_g90"} bg-[length:48px_48px] 
            bg-center bg-no-repeat after:!text-[0] tablet:!hidden`} />
        </SwiperWrapper>}
    </section>;
}
const SwiperWrapper = styled.div.withConfig({
  displayName: "BannerC__SwiperWrapper",
  componentId: "sc-j1zzlt-0"
})([".swiper{", " .swiper-slide{", "}}.swiper-pagination{", " .swiper-pagination-bullet{", "}.swiper-pagination-bullet:first-child{", "}.swiper-pagination-bullet-active{", "}}"], {}, {}, {
  "bottom": "20px",
  "width": "100%",
  "@media (max-width: 767px)": {
    "bottom": "7px"
  }
}, {
  "margin": "0 0 0 8px",
  "height": "8px",
  "width": "8px",
  "borderRadius": "100%",
  "opacity": "0.25",
  "@media (max-width: 767px)": {
    "height": "6px",
    "width": "6px"
  }
}, {
  "marginLeft": "0px"
}, {
  "opacity": "1"
});