import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
export const PledgeBanner = ({
  hotelPage
}) => {
  const {
    t
  } = useTranslation("common");
  const [infoCard, setInfoCard] = useState([]);
  const [animationIndex, setAnimationIndex] = useState(0);
  const [animation, setAnimation] = useState("flipIn"); // 애니메이션 상태
  const router = useRouter();
  useEffect(() => {
    const cards = [{
      icon: "/images/common/ic_security_ou_multi.png",
      infoText: t("pledge.infocard.security.infotext"),
      link: "/pledge?section=0"
    }, {
      icon: "/images/common/ic_safety payment_ou_multi.png",
      infoText: t("pledge.infocard.payment.infotext"),
      link: "/pledge?section=1"
    }, {
      icon: "/images/common/ic_profit_ou_multi.png",
      infoText: t("pledge.infocard.profit.infotext"),
      link: "/pledge?section=2"
    }, {
      icon: "/images/common/ic_cs_ou_multi.png",
      infoText: t("pledge.infocard.cs.infotext"),
      link: "/pledge?section=3"
    }];
    setInfoCard(cards);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimation("flipOut"); // flipOut 애니메이션 적용
      setTimeout(() => {
        setAnimationIndex(prevIndex => (prevIndex + 1) % infoCard.length);
        setAnimation("flipIn"); // 새 인덱스 적용 후 flipIn 애니메이션으로 변경
      }, 600); // 애니메이션 지속 시간
    }, 2500); // 다음 문장으로 넘어가는 시간 간격

    return () => clearInterval(intervalId);
  }, [infoCard]);
  return <>
      <div className={`flex cursor-pointer justify-between py-3 ${hotelPage ? "border-t border-Bg-LightGray bg-white px-5" : "rounded-lg bg-Bg-LightGray px-[14px]"}`} onClick={() => {
      router.push(infoCard[animationIndex]?.link);
    }}>
        {infoCard.length > 0 ? <div className={`flex items-center gap-1 ${animation === "flipIn" ? "animate-flipIn" : "animate-flipOut"}`}>
            <Image src={infoCard[animationIndex].icon} alt="iconImg" width={20} height={20} />
            <span className={`${hotelPage ? "SB_14_100_Regular text-Gray-800" : "SB_14_100_Medium text-Gray-900"}`}>
              {infoCard[animationIndex].infoText}
            </span>
          </div> : <div></div>}
        <Image src="/images/main/ic_arrow_right_ou_b_g90.svg" alt="arrowIcon" width={14} height={14} />
      </div>
      {hotelPage && <div className="h-2 w-full bg-Bg-LightGray"></div>}
    </>;
};