import { FC, useEffect, useState, useMemo } from "react";
import * as Hangul from "hangul-js";

import { TypingProps } from "./types";

type TypingStatus =
  | "disabled"
  | "preDelaying"
  | "doing"
  | "postDelaying"
  | "done";

const getProgress = (disassembled: string[][], length: number) => {
  let result = "";
  let charCount = 0;
  for (let i = 0; i < disassembled.length; i++) {
    const item = disassembled[i];
    if (charCount + item.length <= length) {
      result += Hangul.assemble(item);
      charCount += item.length;
    } else {
      result += Hangul.assemble(
        item.slice(0, length - (charCount + item.length))
      );
      break;
    }
  }
  return result;
};

const Typing: FC<TypingProps> = ({
  str,
  children,
  speed = 100,
  preDelay,
  postDelay = 1000,
  loop,
  disabled,
  Tag = "p",
  onDone,
  ...props
}) => {
  const source = children || str;
  if (typeof source !== "string") {
    throw new Error("children or str must be string");
  }
  const regex = /([\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF])/g;
  const onlyEmojiRegExp = /[\uD800-\uDFFF].|[\u2000-\u3300]./;
  const disassembled = useMemo(() => {
    // source.trim().split("").map(ch => Hangul.disassemble(ch)),
    const convertStr = source.trim().match(regex) || [];
    return convertStr.map(ch => (onlyEmojiRegExp.test(ch) ? [ch] : Hangul.disassemble(ch)));
  }, [source]);

  const [status, setStatus] = useState<TypingStatus>();

  useEffect(() => {
    if (disassembled?.length) {
      setStatus(disabled ? "disabled" : preDelay !== 0 ? "preDelaying" : "doing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disassembled /*, disabled, preDelay */]);

  useEffect(() => {
    if (disassembled?.length && status === "disabled" && !disabled) {
      setStatus(preDelay !== 0 ? "preDelaying" : "doing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled /*, disassembled, status, preDelay */]);

  useEffect(() => {
    let timeout = status === "preDelaying" && setTimeout(() => setStatus("doing"), preDelay);
    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, disassembled /*, preDelay */]);

  const [result, setResult] = useState("");

  useEffect(() => {
    if (status !== "doing") {
      return;
    }

    setResult("");
    const length = disassembled.reduce((acc, char) => char.length + acc, 0);
    let currentLength = 1;
    let interval = setInterval(() => {
      setResult(getProgress(disassembled, currentLength));
      currentLength += 1;
      if (currentLength > length) {
        clearInterval(interval);
        setStatus(postDelay ? "postDelaying" : "done");
      }
    }, speed);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disassembled, status /*, postDelay, speed */]);

  useEffect(() => {
    let timeout = status === "postDelaying" && setTimeout(() => setStatus("done"), postDelay);
    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, disassembled /*, postDelay */]);

  useEffect(() => {
    if (status === "done") {
      onDone && onDone();
      if (loop) setStatus("preDelaying");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status /*, onDone*/]);

  return <Tag {...props}>{result}</Tag>;
};

export default Typing;
