import ImageFallback from "@components/common/image";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useDomain } from "@modules/hooks/useDomain";

/**
 * ListG 타입에서 처음 사용된 아이템 컴포넌트
 * @param imgUrl // 이미지 url
 * @param starRating // 호텔 성급
 * @param title // 타이틀 (호텔명)
 * @param subTitle // 서브타이틀 (부가 설명)
 * @param price // 가격
 * @param zeroPrice // 가격이 0 원일때 표현할 컴포넌트
 * @param label // 이미지 위의 레이블
 * @param labelTextColor // 위 레이블의 글자 색상
 * @param labelBGColor // 위 레이블의 배경 색상
 * @returns {JSX.Element}
 * @constructor
 */
export default function ItemG({
  imgUrl,
  starRating,
  title,
  subTitle,
  price,
  zeroPrice,
  label,
  labelTextColor,
  labelBGColor
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const priceFormatter = new Intl.NumberFormat(locale);
  const isTTBB = useDomain();
  return <div className="relative flex flex-row">
      {/*좌측 이미지 영역*/}
      <div className="relative h-[140px] w-[140px] shrink-0 overflow-hidden rounded-[12px] tablet:h-[134px] tablet:w-[100px] tablet:rounded-[8px]">
        {/*이미지 위 레이블 레이어*/}
        {label && <div className="absolute z-[10] inline-flex justify-center rounded-br-[12px] rounded-tl-[12px] p-2 tablet:rounded-br-[8px] mobile:rounded-tl-[8px]" style={{
        backgroundColor: labelBGColor || (isTTBB ? "#FFCC00" : "#7B3CFF")
      }}>
            <span className="C_12_100_Bold tablet:SC_11_100_Bold" style={{
          color: labelTextColor || (isTTBB ? "#121212" : "#FFF")
        }}>
              {label}
            </span>
          </div>}
        {/*이미지*/}
        <ImageFallback key={imgUrl} fill sizes="(max-width: 576px) 100px, 200px" className="rounded-[12px] object-cover mobile:rounded-[8px]" src={imgUrl} blur="blur" alt="thumbnailImg" />
      </div>

      {/*우측 컨텐츠 영역*/}
      <div className="relative ml-3 flex grow flex-col py-1">
        {/*성급*/}
        <p className={`C_12_100_Medium block ${isTTBB ? "text-Gray-500" : "text-Gray-400"}`}>
          {starRating > 0 && `${starRating}${t("search.txt.star")}`}
        </p>

        {/*호텔 타이틀*/}
        <p className={`B_16_145_Bold mt-1.5 line-clamp-2 text-ellipsis whitespace-normal break-all tablet:mt-1 ${isTTBB ? "text-Gray-900" : "text-Gray-800"}`}>
          {title}
        </p>

        {/*부가 텍스트 영역*/}
        {subTitle && <p className={`SB_14_145_Medium tablet:C_12_145_Medium mt-0.5 line-clamp-2 text-ellipsis whitespace-pre-line break-all ${isTTBB ? "text-PositiveBlue" : "text-Primary500"}`}>
            {subTitle}
          </p>}

        {/*가격 영역*/}
        {!!price && <div className="H4_24_100_Bold tablet:H5_20_100_Bold absolute bottom-0 w-max text-Gray-900">
            {locale === "en-US" && "$"}
            {priceFormatter.format(price)}
            <sub className="SB_14_100_Regular tablet:C_12_100_Medium relative bottom-0 inline-block p-[0_0_0_4px] align-middle">
              {locale === "ko-KR" && "원~"}
              {locale === "en-US" && "~"}
            </sub>
          </div>}
        {price === 0 && !!zeroPrice && zeroPrice()}
      </div>
    </div>;
}