import ImageFallback from "components/common/image";
import { useState } from "react";
import Link from "next/link";
import { Contains } from "@constants/contains";
export default function ListC({
  info
}) {
  const [mouseEnter, setMouseEnter] = useState(false);
  const onMouseEnter = () => {
    setMouseEnter(true);
  };
  const onMouseLeave = () => {
    setMouseEnter(false);
  };
  return <section className="mGroup5 mb-[128px] overflow-hidden p-[40px_0_40px] mobile:mb-[40px] mobile:p-[20px_0_20px]">
      <div className="relative flex h-full w-full" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {[0, 1].map((v, i) => {
        return <div className={`flex animate-scroll flex-row ${mouseEnter ? "animate-hover-stop" : ""}`} key={i} aria-hidden={i === 1}>
              {info.items?.map((item, index) => {
            return <Link href={{
              pathname: "/search",
              query: {
                query: item.region_name,
                searchId: item.region_id,
                searchType: Contains.REGION,
                searchText: item.region_name
              }
            }} key={index} className="ml-[100px] flex w-max grow items-center mobile:ml-[24px]">
                    <div className="img relative h-[88px] w-[88px] shrink-0 overflow-hidden rounded-[100px] bg-[#CECECE] mobile:h-[66px] mobile:w-[66px]">
                      <ImageFallback src={item.main_image_url} sizes="(min-width: 576px) 350px, 60vw" width={200} height={200} alt={v.main_image_url} />
                    </div>
                    <span className="D6_32_100_Bold tablet:H3_26_145_Bold mobile:H5_20_145_Bold ml-[30px] block min-w-fit text-Gray-900 mobile:ml-[16px]">
                      {item.title}
                    </span>
                  </Link>;
          })}
            </div>;
      })}
      </div>
    </section>;
}