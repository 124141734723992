import Title01 from "../common/Title01";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Commify } from "@modules/utils/Commify";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Contains } from "@constants/contains";
import BulletEm from "../common/BulletEm";
import styles from "../Main.module.css";
import dayjs from "dayjs";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import { useRouter } from "next/router";
import ImageFallback from "@components/common/image";
import TabBtn from "@components/Button/TabBtn";
export default function ListF({
  info
}) {
  const {
    locale
  } = useRouter();
  const {
    t
  } = useTranslation("common");
  const [selectRegion, setSelectRegion] = useState([]);
  const [selectRegionName, setSelectRegionName] = useState("");
  const selectRegionHandler = title => {
    info.items.map(item => {
      if (String(item.title) === title) {
        setSelectRegion(item.hotels);
        setSelectRegionName(item.title);
      }
    });
  };
  useEffect(() => {
    if (info.items) {
      setSelectRegion(info.items[0].hotels);
      setSelectRegionName(info.items[0].title);
    }
  }, [info]);
  return <section className={`${styles.mGroup11} mGroup11 relative mb-[128px] pb-[54px] pt-[40px] mobile:mb-[40px] mobile:pb-[20px] mobile:pt-[20px]`}>
      {info.title && <Title01>{info.title}</Title01>}
      <div className="tab mb-[44px] flex justify-center
                   scrollbar-hide
                   tablet:mb-[24px] mobile:m-[0_0_24px] mobile:justify-start mobile:overflow-x-auto mobile:overflow-y-hidden mobile:whitespace-nowrap mobile:px-[16px]
                   mobile:py-0">
        {info.items?.map((item, index) => {
        if (item.hotels?.length < 5) return false;
        return <TabBtn key={index} onClick={() => {
          selectRegionHandler(`${item.title}`);
        }} isSelected={selectRegionName === item.title} text={item.title} className={`${index > 0 ? "ml-[8px]" : "pl-[16px]"} B_16_100_Medium h-[40px] p-[0_20px_0_16px]
                           mobile:ml-[6px] mobile:h-[32px] mobile:py-0 mobile:!pl-[8px] mobile:pr-[14px] mobile:text-[14px]
              `} />;
      })}
      </div>
      <ListTumb className="listTumb tp4
                   mx-[24px] tablet:my-0
                   mobile:m-0 mobile:overflow-x-auto mobile:overflow-y-hidden mobile:whitespace-nowrap mobile:scrollbar-hide">
        <ul className="m-[-22px_auto_0] flex max-w-[1180px]
                     tablet:m-0 tablet:w-auto
                     mobile:mx-[16px] mobile:my-0 mobile:inline-flex">
          {selectRegion.map((item, index) => {
          if (item.hotels?.length < 5) return false;
          if (index > 4) return false;
          return <HotelCard key={index}>
                <Link href={{
              pathname: "/hotels/[id]",
              query: {
                id: item.id,
                "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
                "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
                ...getRoomQuery(getSearchRoomsCookie()),
                query: item.name,
                searchId: item.id,
                searchType: Contains.HOTEL
              }
            }} className="block">
                  <div className="img relative overflow-hidden rounded-[12px] pb-[100%] after:absolute after:bottom-0 after:right-0 after:h-[39%] after:w-[100%] after:bg-[length:contain] after:bg-[right_center] after:bg-no-repeat
                             mobile:after:absolute mobile:after:bottom-0 mobile:after:right-0 mobile:after:h-[49%] mobile:after:content-['']">
                    <ImageFallback fill sizes="(max-width: 768px) 140px, 280px" src={item.thumbnail_url} className="absolute left-0 top-0 h-full w-full object-cover" alt="" />
                  </div>
                  <div className="cnt pt-[14px] text-left mobile:mr-[8px] mobile:pt-[14px]">
                    <span className="C_12_100_Medium m-0 block overflow-hidden text-Gray-400 ">
                      {item?.star_rating > 0 ? `${item?.star_rating}${t("search.txt.star")}` : ""}
                      {item?.star_rating > 0 && ` · `}
                      {item.region_name}
                    </span>
                    <p className="B_16_145_Medium overflow-hidden text-ellipsis whitespace-nowrap pt-[6px] text-Gray-800  mobile:pt-[6px]">
                      {item.name}
                    </p>
                    <div className="price H4_24_100_Bold mobile:H5_20_100_Bold pt-[5px] mobile:pt-[5px]">
                      {locale === "en-US" && "$"}
                      {Commify(item.price)}
                      <sub className="SB_14_100_Regular mobile:C_12_100_Medium relative top-[1px] inline-block p-[0_0_0_4px] align-middle text-Gray-900 mobile:top-[1px] mobile:p-[0_0_0_4px]">
                        {locale === "ko-KR" && "원~"}
                        {locale === "en-US" && "~"}
                      </sub>
                    </div>
                  </div>
                </Link>
              </HotelCard>;
        })}
        </ul>
      </ListTumb>
    </section>;
}
const ListTumb = styled.div.withConfig({
  displayName: "ListF__ListTumb",
  componentId: "sc-1a9qb4j-0"
})(["::-webkit-scrollbar{", "}"], {
  "height": "0"
});
const HotelCard = styled.li.withConfig({
  displayName: "ListF__HotelCard",
  componentId: "sc-1a9qb4j-1"
})(["", " :first-child{", "}:first-child .img{", "}.img{", "}:nth-child(1) .img{", "}:nth-child(2) .img{", "}:nth-child(3) .img{", "}:nth-child(4) .img{", "}:nth-child(5) .img{", "}"], {
  "float": "none",
  "marginLeft": "20px",
  "marginTop": "22px",
  "maxWidth": "205px",
  "flex": "1 1 205px",
  "@media (max-width: 1024px)": {
    "marginLeft": "16px",
    "marginTop": "0px",
    "width": "calc((100% - 48px) / 5) !important",
    "maxWidth": "100% !important",
    "flex": "initial"
  },
  "@media (max-width: 767px)": {
    "marginLeft": "12px",
    "width": "154px !important",
    "maxWidth": "154px",
    "flex": "initial"
  }
}, {
  "marginLeft": "0px",
  "maxWidth": "280px",
  "flex": "1 1 280px",
  "@media (max-width: 1024px)": {
    "flex": "initial"
  },
  "@media (max-width: 767px)": {
    "flex": "initial"
  }
}, {
  "paddingBottom": "73.5%",
  "@media (max-width: 1024px)": {
    "paddingBottom": "100%"
  },
  "@media (max-width: 767px)": {
    "height": "154px",
    "width": "154px",
    "paddingBottom": "0"
  }
}, {
  "@media (max-width: 767px)": {
    "height": "154px",
    "width": "154px",
    "paddingBottom": "0"
  }
}, {
  "::after": {
    "content": "var(--tw-content)",
    "backgroundImage": "url('/images/main/img_top-crewreview01.svg')"
  }
}, {
  "::after": {
    "content": "var(--tw-content)",
    "backgroundImage": "url('/images/main/img_top-crewreview02.svg')"
  }
}, {
  "::after": {
    "content": "var(--tw-content)",
    "backgroundImage": "url('/images/main/img_top-crewreview03.svg')"
  }
}, {
  "::after": {
    "content": "var(--tw-content)",
    "backgroundImage": "url('/images/main/img_top-crewreview04.svg')"
  }
}, {
  "::after": {
    "content": "var(--tw-content)",
    "backgroundImage": "url('/images/main/img_top-crewreview05.svg')"
  }
});