import { useEffect, useState } from "react";
import MainTitle from "@components/common/title/MainTitle";
import MainTab from "@components/common/tab/MainTab";
import Link from "next/link";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import { Contains } from "@constants/contains";
import ItemG from "@components/common/card/ItemG";
import RoundedSqBtn from "@components/Button/RoundedSqBtn";
import { useTranslation } from "next-i18next";
import MainSubTitle from "@components/common/title/MainSubTitle";
import { useRouter } from "next/router";
import { gtagEvent } from "@modules/lib/gtm";
export default function ListE({
  info
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const [listData, setListData] = useState([]);
  const [selectTab, setSelectTab] = useState(0);
  useEffect(() => {
    if (info.items) {
      setListData(info.items);
    }
  }, [info]);
  useEffect(() => {
    if (locale === "ko-KR") {
      dayjs.locale("ko");
    } else {
      dayjs.locale("en");
    }
  }, [locale]);
  const makeDateFormat = () => {
    const today = dayjs();
    const startDate = today.add(7, "day");
    const endDate = today.add(8, "day");
    const formattedStartDate = startDate.format("M.D (ddd)");
    const formattedEndDate = endDate.format("M.D (ddd)");
    return `${formattedStartDate} - ${formattedEndDate}`;
  };
  const tabCallbacks = index => {
    setSelectTab(index);
  };
  return <section id="mGroup9" className="relative mb-[128px] tablet:mb-[96px] mobile:mb-10">
      {/*타이틀 영역*/}
      {info.title && <MainTitle className="mt-10 mb-3 tablet:mx-6 tablet:mt-4 tablet:mb-2 mobile:mt-0">
          {info.title}
        </MainTitle>}

      {/*서브 타이틀 영역*/}
      <MainSubTitle className="mb-8 tablet:mb-6 mobile:mb-4">
        {t("main.popular.date", {
        dates: makeDateFormat()
      })}
        {/*{makeDateFormat()}*/}
      </MainSubTitle>

      {/*탭 영역*/}
      <MainTab tabArr={info.items} selectTab={selectTab} callbacks={tabCallbacks} />

      {/*컨텐츠 영역*/}
      <div className="mx-auto mt-10 mb-[46px] max-w-[1180px] overflow-y-hidden overflow-x-scroll scrollbar-hide tablet:mx-6 tablet:mt-6 tablet:mb-4 tablet:w-auto mobile:mx-0 mobile:mt-3 mobile:mb-0" onTouchStart={() => {
      gtagEvent("sliderSwipe_main_listE", {});
    }}>
        <div className="grid grid-cols-3 gap-5 tablet:gap-x-2.5 tablet:gap-y-5 mobile:mx-4 mobile:w-fit mobile:auto-cols-min mobile:grid-flow-col mobile:grid-cols-none mobile:grid-rows-3 mobile:gap-4">
          {listData && listData[selectTab]?.hotels?.map((v, i) => <Link key={i} href={{
          pathname: "/hotels/[id]",
          query: {
            id: v.id,
            "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
            "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
            ...getRoomQuery(getSearchRoomsCookie()),
            query: v.name,
            searchId: v.id,
            searchType: Contains.HOTEL
          }
        }} className="flex justify-between mobile:min-w-[304px]">
                <ItemG imgUrl={v.thumbnail_url} starRating={v.star_rating} title={v.name} price={v.price} label={v.label} zeroPrice={() => {
            return <div className="absolute bottom-0 flex w-max flex-col">
                        <span className="SB_14_100_Regular tablet:C_12_100_Regular mb-1.5 text-PositiveBlue">
                          {t("main.popular.soldout")}
                        </span>
                        <span className="H6_18_100_Bold tablet:B_16_100_Bold text-Gray-900">
                          {t("main.popular.noprice")}
                        </span>
                      </div>;
          }} />
              </Link>)}
        </div>
      </div>

      {/*전체 보기 버튼 영역*/}
      <Link href={{
      pathname: "/search",
      query: {
        query: listData[selectTab]?.title,
        searchId: listData[selectTab]?.region_id,
        searchType: Contains.REGION,
        searchText: listData[selectTab]?.title
      }
    }} className="mx-4 flex">
        <RoundedSqBtn type="clear" text={t("main.btn.all.hotels", {
        도시명: listData[selectTab]?.title
      })} className="btnHotelView B_16_100_Medium mobile:SB_14_100_Medium m-[0_auto] flex h-[56px] w-[300px] items-center justify-center rounded-[50px] text-Gray-900
                      mobile:m-[32px_0_0] mobile:h-[40px] mobile:w-full" />
      </Link>
    </section>;
}