import { useEffect, useState } from "react";
import Link from "next/link";
import dayjs from "dayjs";
import { Contains } from "@constants/contains";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import MainTitle from "@components/common/title/MainTitle";
import MainTab from "@components/common/tab/MainTab";
import ItemG from "@components/common/card/ItemG";
import { gtagEvent } from "@modules/lib/gtm";
export default function ListG({
  info
}) {
  const [listData, setListData] = useState([]);
  const [selectTab, setSelectTab] = useState(0);
  useEffect(() => {
    if (info.items) {
      setListData(info.items);
    }
  }, [info]);
  const tabCallbacks = index => {
    setSelectTab(index);
  };
  return <section id="mGroup13" className="relative mb-[128px] tablet:mb-[96px] mobile:mb-10">
      {/*타이틀 영역*/}
      {info.title && <MainTitle className="mt-10 mb-8 tablet:mx-6 tablet:mt-4 tablet:mb-3 mobile:mt-0 mobile:mb-4">
          {info.title}
        </MainTitle>}

      {/*탭 영역*/}
      <MainTab tabArr={info.items} selectTab={selectTab} callbacks={tabCallbacks} />

      {/*컨텐츠 영역*/}
      <div className="mx-auto mt-10 mb-[46px] max-w-[1180px] overflow-y-hidden overflow-x-scroll scrollbar-hide tablet:mx-6 tablet:mt-6 tablet:mb-4 tablet:w-auto mobile:mx-0 mobile:mt-3 mobile:mb-0" onTouchStart={() => {
      gtagEvent("sliderSwipe_main_listG", {});
    }}>
        <div className="grid grid-cols-3 gap-5 tablet:gap-x-2.5 tablet:gap-y-5 mobile:mx-4 mobile:w-fit mobile:auto-cols-min mobile:grid-flow-col mobile:grid-cols-none mobile:grid-rows-3 mobile:gap-4">
          {listData && listData[selectTab]?.hotels?.map((v, i) => <Link key={i} href={{
          pathname: "/hotels/[id]",
          query: {
            id: v.hotel_id,
            "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
            "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
            ...getRoomQuery(getSearchRoomsCookie()),
            query: v.name,
            searchId: v.hotel_id,
            searchType: Contains.HOTEL
          }
        }} className="flex justify-between mobile:min-w-[304px]">
                <ItemG imgUrl={v.thumbnail_url} starRating={v.star_rating} title={v.hotel_name} subTitle={v.description} price={v.price} label={v.label} labelTextColor={v.label_color} labelBGColor={v.label_background_color} />
              </Link>)}
        </div>
      </div>
    </section>;
}