import styles from "./CookiePopup.module.css";
import { cookies } from "./CookieConsent";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
export default function CookieModal({
  handleSubmit,
  cookiesRef
}) {
  const {
    locale
  } = useRouter();
  const {
    t
  } = useTranslation("common");
  const data = {
    description: t("setting.popup.terms.content"),
    cookies: [{
      name: "necessary",
      title: t("setting.storage.preferences.title.policy"),
      description: t("setting.storage.preferences.title.policy.content"),
      disabled: true
    }, {
      name: "functionality",
      title: t("setting.storage.preferences.title.functionality.cookies"),
      description: t("setting.storage.preferences.title.functionality.cookies.content"),
      disabled: false
    }, {
      name: "analytics",
      title: t("setting.storage.preferences.title.analytics.cookies"),
      description: t("setting.storage.preferences.title.analytics.cookies.content"),
      disabled: false
    }, {
      name: "advertising",
      title: t("setting.storage.preferences.title.advertising.cookies"),
      description: t("setting.storage.preferences.title.advertising.cookies.content"),
      disabled: false
    }]
  };
  return <div id="layerSaveSetup" className={styles.layerSaveSetupPop}>
      <div className={styles.layerWrap}>
        <div className={styles.bgModal70}></div>
        <div className={styles.layerSaveSetup}>
          <h3 className="H5_20_100_Bold mobile:H6_18_100_Bold">
            {t("setting.storage.preferences.title")}
          </h3>
          <div className={styles.conts}>
            <p className="B_16_160_Regular fcBlack3 mobile:P_15_160_Regular">{data.description}</p>
            <a href={`/${locale}/terms/service`} target="_blank" rel="noreferrer" className={styles.btnCookie}>
              {t("setting.storage.preferences.policy")}
            </a>
            {data.cookies.map(item => {
            return <CheckBoxItem data={item} cookiesRef={cookiesRef} key={item.name} />;
          })}
          </div>
          <div className={styles.btns}>
            <a onClick={handleSubmit} className={`${styles.btnLySave} B_16_100_Bold cursor-pointer`}>
              {locale === "ko-KR" ? "저장" : "Save"}
            </a>
          </div>
          <a onClick={handleSubmit} className={`txtHide cursor-pointer ${styles.btnLyClose}`}>
            {locale === "ko-KR" ? "닫기" : "Close"}
          </a>
        </div>
      </div>
    </div>;
}
const CheckBoxItem = ({
  data,
  cookiesRef
}) => {
  const {
    title,
    description,
    disabled,
    name
  } = data;
  const handleClickCheckBox = e => {
    // console.log(e.target.checked);
    cookiesRef.current[name] = e.target.checked ? cookies[name] : [];
  };
  return <div className={styles.box}>
      <div className={styles.titSt}>
        <h4 className="B_16_100_Bold">{title}</h4>
        <div className={styles.toggle}>
          <input id={`toggle-${name}`} type="checkbox" className="peer sr-only" defaultChecked={disabled} disabled={disabled} onChange={handleClickCheckBox} />
          <label htmlFor={`toggle-${name}`} className={styles.toggleSwitch}>
            <span className={styles.toggleButton}></span>
          </label>
        </div>
      </div>
      <p className="P_15_160_Regular mobile:SB_14_160_Regular">{description}</p>
    </div>;
};