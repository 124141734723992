import SearchBtn from "@components/Button/SearchBtn";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useDomain } from "@modules/hooks/useDomain";
import dayjs from "dayjs";
export default function ScrollupSearchBox({
  setIsSearchClick,
  setOpenSearchBar,
  searchData,
  setSearchData,
  selectPeopleCnt
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const isTTBB = useDomain();
  const borderClass = isTTBB ? "border-Gray-200" : "border-Gray-50";
  const minWidth = isTTBB ? "min-w-[372px]" : "min-w-[524px]";
  const translateSearchBox = typeof window !== "undefined" && window.innerWidth < 1280;
  return <>
      <div className={`B_16_100_Regular fixed top-[64px] z-[13] mx-[27%] w-[46%] text-Gray-700 tablet:hidden`}>
        <div className={`relative mx-auto flex w-fit flex-row items-center py-0 pl-[24px] pr-[54px] ${minWidth} h-[48px] ${borderClass} ${isTTBB && translateSearchBox && "mx-0 -translate-x-1/4"} cursor-pointer gap-[24px] rounded-full border border-solid bg-white`}>
          <div className={`relative flex h-full max-w-[144px] items-center truncate pl-[20px] before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-ic_locate_fi_g90 before:bg-[0_center] before:bg-no-repeat before:content-['']`} onClick={() => {
          setIsSearchClick({
            state: true,
            clickItem: "text"
          });
          setOpenSearchBar(true);
        }}>
            {searchData.query ? searchData.query : t("searchbar.search.placeholder")}
          </div>
          <>
            <div className={`relative flex h-full items-center pl-[20px] before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-[url('/images/main/ic_calendar_fi_g90.svg')] before:bg-[0_center] before:bg-no-repeat before:content-['']`} onClick={() => {
            setIsSearchClick({
              state: true,
              clickItem: "date"
            });
            setSearchData({
              ...searchData,
              showDaysLayer: true
            });
            setOpenSearchBar(true);
          }}>
              {dayjs(searchData.startDate).locale(locale).format(t("search.date.format"))} -{" "}
              {dayjs(searchData.endDate).locale(locale).format(t("search.date.format"))}
            </div>
            <div className={`relative flex h-full items-center pl-[20px] before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-[url('/images/main/ic_guest_fi_b_g90.svg')] before:bg-[0_center] before:bg-no-repeat before:content-['']`} onClick={() => {
            setIsSearchClick({
              state: true,
              clickItem: "room"
            });
            setOpenSearchBar(true);
          }}>
              {t("searchbar.txt.people.cnt", {
              n: selectPeopleCnt
            })}
            </div>
          </>

          <SearchBtn type="main_scrollUp" onClick={() => {
          setIsSearchClick({
            state: true,
            clickItem: "button"
          });
          setOpenSearchBar(true);
        }} />
        </div>
      </div>
    </>;
}