import React, { useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import ImageFallback from "@components/common/image";
import dynamic from "next/dynamic";
import { useAtom } from "jotai/index";
import { navbarSearchboxAtom, visibleBigBannerAtom, webMainAtom } from "@store/global";
import SearchHeader from "@components/layout/SearchHeader";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie, setSearchDateCookie, setSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import { disableBodyScrollLock, enableBodyScrollLock } from "@modules/utils/BodyScrollLock";
import { BIG_BANNER_ITEM_CONTENTS_TYPE, BIG_BANNER_TYPE, LANDING_TYPE, MAIN_BANNER_KEYWORD_TAB_TYPE, SEARCH_INPUT_DELAY, SEARCH_TYPE_ALLOW_MAP, SEARCH_TYPE_TINVEN, TYPE } from "@constants/common";
import { useDebounce } from "@modules/hooks/useDebounce";
import IcSearchClear from "@public/images/common/ic_search_clear.svg";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
import FilterBox from "@components/layout/SearchHeader/FilterBox";
import DatePickerBox from "@components/layout/SearchHeader/DatePickerBox";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMobile } from "@modules/hooks/useMedia";
import { getSearchMeta, getWebMain } from "@modules/api/Search";
import RoomCard from "@components/layout/SearchHeader/RoomCard";
import { useDomain } from "@modules/hooks/useDomain";
import dayjs from "dayjs";
import { fPixelTrack } from "@modules/lib/fpixel/track";
import { FACEBOOK_PIXEL_TRACKING_TYPE } from "@constants/scripts";
import { Contains } from "@constants/contains";
import SearchBtnIcon from "@public/images/common/ic_search_ou_b_wh.svg";
import { useMediaQuery } from "react-responsive";
import { throttle } from "lodash-es";
import { hexToRgbA } from "@modules/utils/hexToRgbA";
import Image from "next/image";
import { Commify } from "@modules/utils/Commify";
import { IsTripbtozApp, IsTTBBApp } from "@modules/utils/TripbtozApp";
import { setSearchHistory } from "@modules/utils/searchHistory";
import styles from "@components/main/Main.module.css";
const DynamicReactPlayer = dynamic(() => import("react-player"), {
  ssr: false
});
const initFocusState = {
  search: false,
  date: false,
  room: false
};
const SearchBoxV2 = React.forwardRef((props, ref) => {
  SearchBoxV2.displayName = "SearchBoxV2";
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const router = useRouter();
  const isDesktop = useMediaQuery({
    minDeviceWidth: 1025
  });
  const isTTBB = useDomain();
  const [isLoaded, setIsLoaded] = useState(false);
  const [innerWidth, setInnerWidth] = useState(false);
  const [webMain, setWebMain] = useAtom(webMainAtom); // 메인 데이터 (추천 키워드 및 디폴트 날짜등)
  const [isFocus, setIsFocus] = useState(initFocusState); // 검색 박스들 포커스
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // 현재 슬라이드 Index
  const [mainColor, setMainColor] = useState("#ffffff"); // 메인 배경 컬러
  const [videoIndex, setVideoIndex] = useState(0); // 현재 재생 중인 동영상의 인덱스
  const [playing, setPlaying] = useState(false);
  const [navbarSearchBoxClick, setNavbarSearchBoxClick] = useAtom(navbarSearchboxAtom); //navbar에 있는 서치박스 클릭 여부
  const [isBigBannerVisible] = useAtom(visibleBigBannerAtom); // BigBanner가 뷰포트에 보이는지 여부

  const [toggleTab, setToggleTab] = useState(MAIN_BANNER_KEYWORD_TAB_TYPE.INTERNAL); // 급상승 인기도시 국내 해외 토글
  const [rankData, setRankData] = useState({}); // SEARCH_C 데이터
  const [videoErrors, setVideoErrors] = useState([]); // 비디오 에러 상태 배열
  const [filterBoxChanged, setFilterBoxChanged] = useState(false);
  const [recKeywordData, setRecKeywordData] = useState(t("searchbar.search.placeholder"));
  const [videoLoading, setVideoLoading] = useState([false, false, false]); // 비디오 로딩 완료 여부
  const [hovering, setHovering] = useState(false); // 마우스 오버 중인지
  const [lastHoveredIndex, setLastHoveredIndex] = useState(null); // 마지막 마우스 오버 영상 index
  const [isHovered, setIsHovered] = useState(false); // 우측 빅배너 영역 마우스 오버 체크
  const videoRefs = useRef([]);
  const [swiper, setSwiper] = useState(null);
  const [userClickNaviBtn, setUserClickNaviBtn] = useState(false); // 사용자가 네비게이션 슬라이드 버튼을 클릭했는지 여부
  const totalSlides = webMain?.banner?.big_banner.length || 0;

  // ----------- SearchHeader를 위한 기존 state 및 함수 ------------
  const [searchData, setSearchData] = useState({
    searchId: "",
    searchType: "",
    query: "",
    startDate: null,
    endDate: null,
    roomsInfo: null,
    showDaysLayer: false,
    searchText: ""
  });
  // dim
  const [viewState, setViewState] = useState({
    dim: false
  });
  const [isSearchTopFixed, setIsSearchTopFixed] = useState(false);
  const [isSearchScrollY, setIsSearchScrollY] = useState(false);
  const [isSearchClick, setIsSearchClick] = useState({
    state: false,
    clickItem: null
  });

  // 검색어
  const [searchMetaData, setSearchMetaData] = useState(null);
  const [searchBoxTextFlag, setSearchBoxTextFlag] = useState(false);
  const searchTextRef = useRef();
  // 인원
  const [selectRoomCnt, setSelectRoomCnt] = useState(1);
  const [selectPeopleCnt, setSelectPeopleCnt] = useState(2);
  const searchSearchBoxRef = useRef();
  const roomsBoxRef = useRef();
  const txtInput = useDebounce(searchData.query, SEARCH_INPUT_DELAY);
  const timeStamp = useRef("");
  const isMobile = useMobile();

  /**
   * 로딩 완료 여부 (datepicker 이슈로 인해)
   */
  useEffect(() => {
    if (!router.isReady) return;
    setIsLoaded(true);
    setNavbarSearchBoxClick(false);
    setIsSearchClick({
      state: false,
      clickItem: null
    });
    setViewState({
      ...viewState,
      dim: false
    });
    disableBodyScrollLock();
    setIsFocus(initFocusState);
  }, [router.isReady]);

  /**
   * 메인 데이터 (추천 키워드 및 디폴트 날짜등) 호출
   */
  useEffect(() => {
    getWebMain(locale).then(data => setWebMain(data.body));
  }, [locale]);
  useEffect(() => {
    if (!searchBoxTextFlag) {
      setSearchData({
        ...searchData,
        query: "",
        searchText: ""
      });
    }
  }, [searchBoxTextFlag]);
  useEffect(() => {
    const onScroll = throttle(() => {
      const {
        scrollY,
        innerWidth
      } = window;
      if (isDesktop) {
        setIsSearchScrollY(scrollY > 200);
      } else {
        setIsSearchScrollY(false);
      }
      setInnerWidth(innerWidth);
      searchTextRef.current?.blur();
      roomSelectCompleteHandle(searchData.roomsInfo);
    }, 100);
    window.addEventListener("scroll", onScroll, {
      passive: true
    });
    window.addEventListener("resize", onScroll, {
      passive: true
    });
    return () => {
      window.removeEventListener("scroll", onScroll, {
        passive: true
      });
      window.removeEventListener("resize", onScroll, {
        passive: true
      });
    };
  }, [searchData.roomsInfo]);
  useEffect(() => {
    setIsSearchTopFixed(isSearchScrollY && isSearchClick.state);
    if (isSearchScrollY) {
      setIsFocus(initFocusState);
    }
  }, [isSearchScrollY, isSearchClick.state]);
  useEffect(() => {
    if (isSearchTopFixed) {
      setViewState({
        ...viewState,
        dim: true
      });
      enableBodyScrollLock();
    } else {
      setViewState({
        ...viewState,
        dim: false
      });
      disableBodyScrollLock();
      setIsSearchClick({
        state: false,
        clickItem: null
      });
      setIsFocus(initFocusState);
    }
  }, [isSearchTopFixed]);
  useEffect(() => {
    const getMetaData = async () => {
      try {
        const dataTimestamp = +new Date();
        let data;
        if (txtInput) {
          data = await getSearchMeta(locale, {
            query: txtInput,
            offset: 0,
            limit: 15,
            indices: [{
              name: "recommend_keyword"
            }, {
              name: "meta_region"
            }, {
              name: "meta_hotel"
            }]
          });
        }
        if (dataTimestamp > timeStamp.current) {
          if (txtInput) {
            setSearchMetaData(data.body);
          } else {
            setSearchMetaData(null);
          }
          timeStamp.current = dataTimestamp;
        }
      } catch (e) {
        console.log("metaDataAPIError :", e);
      }
    };
    getMetaData();
  }, [locale, txtInput]);
  useEffect(() => {
    const roomsInfo = getSearchRoomsCookie();
    setSearchData({
      ...searchData,
      ...getSearchDateCookie(),
      roomsInfo
    });
    roomSelectCompleteHandle(roomsInfo);
  }, []);
  const dimClickHandler = () => {
    setIsSearchClick({
      state: false,
      clickItem: null
    });
    setIsFocus(initFocusState);
  };
  const roomSelectCompleteHandle = (roomsInfo, isClose) => {
    if (!roomsInfo) return;
    setSelectRoomCnt(roomsInfo?.length ?? 0);
    let peopleCnt = 0;
    roomsInfo?.map(info => {
      peopleCnt = peopleCnt + info.people.adultCnt + info.people.childs.length;
    });
    setSelectPeopleCnt(peopleCnt);
    if (isClose === true) {
      setSearchData({
        ...searchData,
        roomsInfo: roomsInfo
      });
      handleFocus("room", false);
    }
  };

  /**
   * 검색 포커스 처리 함수
   * @param key
   * @param value
   */
  const handleFocus = (key, value = true) => {
    setIsFocus({
      ...initFocusState,
      [key]: value
    });
  };
  const searchTextHandle = e => {
    setSearchBoxTextFlag(true);
    setSearchData({
      ...searchData,
      query: e.target.value,
      searchText: e.target.value
    });
  };

  // 검색 버튼
  const searchBtnHandler = () => {
    const {
      innerWidth
    } = window;
    if (innerWidth <= 1024) {
      router.push({
        pathname: "/search/mobile"
      });
      return;
    }
    if (!searchData.query || !searchData.searchId) {
      handleFocus("search");
      return;
    }
    setIsFocus(initFocusState);
    setSearchDateCookie(searchData.startDate, searchData.endDate);
    setSearchRoomsCookie(searchData.roomsInfo);
    setSearchHistory(searchData);
    const query = {
      "check-in": dayjs(searchData.startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(searchData.endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(searchData.roomsInfo),
      query: searchData.query,
      searchId: searchData.searchId,
      searchType: searchData.searchType,
      searchText: searchData.searchText
    };
    if (!isTTBB && process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
      fPixelTrack(FACEBOOK_PIXEL_TRACKING_TYPE.SEARCH, query);
    }
    if (searchData.searchType === Contains.HOTEL) {
      router.push({
        pathname: "/hotels/[id]",
        query: {
          id: searchData.searchId,
          ...query
        }
      }, undefined);
    } else {
      router.push({
        pathname: "/search",
        query: query
      });
    }
  };

  // -------- 여기까지 SearchHeader를 위한 기존 state 및 함수 ----------

  useEffect(() => {
    if (filterBoxChanged) {
      searchBtnHandler();
      setFilterBoxChanged(false); // 플래그를 재설정
    }
  }, [filterBoxChanged]);
  useEffect(() => {
    const selectedTabData = webMain?.keywords?.find(data => data.tab === toggleTab);
    if (selectedTabData) {
      const searchCData = selectedTabData.items.find(item => item.type === "SEARCH_C");
      const searchAData = selectedTabData.items.find(item => item.type === "SEARCH_A");
      if (searchCData) {
        setRankData(searchCData);
      }
      if (searchAData && locale === "ko-KR") {
        setRecKeywordData(searchAData?.keywords?.[0]?.name);
      } else {
        setRecKeywordData(t("searchbar.search.placeholder"));
      }
    }
  }, [locale, toggleTab, webMain]);

  /**
   * 데이터피커의 달력컴포넌트 위치 수정을 위한 처리
   */
  useEffect(() => {
    const datePickerElement = document.querySelector(".react-datepicker");
    if (datePickerElement) {
      datePickerElement.style.left = "200px";
      datePickerElement.style.borderRadius = "24px";
    }
  }, [isFocus.date]);

  /**
   * BigBanner가 뷰포트에 보이면, 헤더 서치박스 초기화
   */
  useEffect(() => {
    if (isBigBannerVisible) {
      setNavbarSearchBoxClick(false);
    }
  }, [isBigBannerVisible]);

  /**
   * 화면 스크롤 됬을때 상단 navBar의 서치박스 클릭시 검색영역 자동 오픈
   */
  useEffect(() => {
    if (isLoaded && navbarSearchBoxClick) {
      setIsSearchClick({
        state: true,
        clickItem: "text"
      });
    }
  }, [isLoaded, navbarSearchBoxClick]);

  /**
   * 슬라이드후 배너 타입이 B or C면 videoIndex를 초기화 하고 자동 재생
   */
  useEffect(() => {
    // setVideoLoading([false, false, false]);
    setHovering(false);
    setLastHoveredIndex(null);
    setMainColor(webMain?.banner?.big_banner[currentSlideIndex]?.background_color || "#ffffff");
    if (webMain?.banner?.big_banner[currentSlideIndex]?.type === BIG_BANNER_TYPE.BANNER_B) {
      if (webMain?.banner?.big_banner[currentSlideIndex]?.contents[0]?.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO) {
        setVideoIndex(0);
        setPlaying(true);
      } else if (webMain?.banner?.big_banner[currentSlideIndex]?.contents[1]?.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO) {
        setVideoIndex(1);
        setPlaying(true);
      }
    } else if (webMain?.banner?.big_banner[currentSlideIndex]?.type === BIG_BANNER_TYPE.BANNER_C) {
      setVideoIndex(0);
      setPlaying(true);
    }
  }, [webMain?.banner?.big_banner, currentSlideIndex]);

  /**
   * 비디오 3초마다 재생중인 인덱스 +1 증가
   */
  useEffect(() => {
    let timer;
    const currentBanner = webMain?.banner?.big_banner[currentSlideIndex];
    const isBannerB = currentBanner?.type === BIG_BANNER_TYPE.BANNER_B;
    const isBannerC = currentBanner?.type === BIG_BANNER_TYPE.BANNER_C;
    const videoContents = currentBanner?.contents.filter(content => content.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO);
    const hasSingleVideo = isBannerB && videoContents.length === 1;
    if (playing && videoIndex < currentBanner?.contents?.length && (isBannerB || isBannerC)) {
      if (hasSingleVideo || isBannerC && videoIndex === lastHoveredIndex) {
        return;
      } else if (!hovering) {
        timer = setTimeout(() => {
          const nextIndex = videoIndex + 1;
          if (nextIndex >= currentBanner?.contents.length) {
            setPlaying(false);
            clearTimeout(timer);
          } else {
            setVideoIndex(nextIndex);
          }
        }, 3000);
      }
    }
    return () => clearTimeout(timer);
  }, [playing, videoIndex, webMain?.banner?.big_banner, currentSlideIndex, hovering]);

  /**
   * 비디오 에러 발생시 처리
   * @param itemIndex
   */
  const handleVideoError = itemIndex => {
    setVideoErrors(prevErrors => {
      const newErrors = [...prevErrors];
      newErrors[itemIndex] = true;
      return newErrors;
    });
  };

  /**
   * 비디오 에러 초기화
   * @param itemIndex
   */
  const resetVideoError = itemIndex => {
    setVideoErrors(prevErrors => {
      const newErrors = [...prevErrors];
      newErrors[itemIndex] = false;
      return newErrors;
    });
  };

  /**
   * 비디오 재생 진행도 초기화
   * @param index
   */
  const resetVideo = index => {
    if (videoRefs.current[index] && videoRefs.current[index].seekTo) {
      videoRefs.current[index].seekTo(0);
    } else if (videoRefs.current[index] && videoRefs.current[index].retry) {
      videoRefs.current[index].retry();
    }
  };

  /**
   * 슬라이드 카드 클릭 이벤트
   * @param item
   * @param card_type
   */
  const slideCardClick = (item, card_type = "") => {
    if (card_type === BIG_BANNER_TYPE.BANNER_C) {
      router.push({
        pathname: `/hotels/${item.hotel_id}`,
        query: {
          id: item.hotel_id,
          "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
          "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
          ...getRoomQuery(getSearchRoomsCookie()),
          query: item.hotel_name,
          searchId: item.hotel_id,
          searchType: Contains.HOTEL
        }
      });
    } else {
      if (IsTripbtozApp() || IsTTBBApp()) {
        if (item.deep_link || item.web_link) {
          location.href = item.deep_link || item.web_link;
        }
      } else if (item.one_link || item.web_link) {
        if (window) window.open(item.one_link || item.web_link);
      }
    }
  };
  const filterBoxChangeCallback = (item, date) => {
    // date가 있는 경우: 최근 검색으로 진입 했을때
    setNavbarSearchBoxClick(false); //초기화
    setIsFocus(initFocusState);
    setSearchBoxTextFlag(true);
    setSearchDateCookie(searchData.startDate, searchData.endDate);
    setSearchRoomsCookie(searchData.roomsInfo);
    const newSearchData = {
      ...searchData,
      startDate: item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_in).toDate() : dayjs(date ? date.searchData.startDate : searchData.startDate).toDate(),
      endDate: item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_out).toDate() : dayjs(date ? date.searchData.endDate : searchData.endDate).toDate(),
      roomsInfo: date ? date.searchData.roomsInfo : searchData.roomsInfo,
      query: date && item.query ? item.query : item.name,
      searchId: date && item.searchId ? item.searchId : item.landing_type === LANDING_TYPE.region_SEARCH ? item.region_id : item.id,
      searchType: date && item.searchType ? item.searchType : item.type === TYPE.HOTEL ? "keyword" : item.type,
      searchText: date && item.searchText ? item.searchText : item.name,
      searchBtnClick: true,
      isMapView: SEARCH_TYPE_ALLOW_MAP.includes(item.searchType) ? false : searchData.isMapView
    };
    setSearchData(newSearchData);
    let query = {
      "check-in": item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_in).format("YYYY.MM.DD") : dayjs(searchData.startDate).format("YYYY.MM.DD"),
      "check-out": item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_out).format("YYYY.MM.DD") : dayjs(searchData.endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(searchData.roomsInfo),
      query: date && item.query ? item.query : item.name,
      searchId: date && item.searchId ? item.searchId : item.landing_type === LANDING_TYPE.region_SEARCH ? item.region_id : item.id,
      searchType: date && item.searchType ? item.searchType : item.type === TYPE.HOTEL ? "keyword" : item.type,
      searchText: date && item.searchText ? item.searchText : item.name,
      landingType: item.landing_type || item.searchType,
      type: (item.type === TYPE.HOTEL ? "keyword" : item.type) || item.searchType
    };
    if (date && item.type !== SEARCH_TYPE_TINVEN) {
      query = {
        ...query,
        ...date.searchQuery
      };
    }
    if (query.type === SEARCH_TYPE_TINVEN) {
      query.tinven_type = item.tinven_type;
    }
    if (!isTTBB && process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
      fPixelTrack(FACEBOOK_PIXEL_TRACKING_TYPE.SEARCH, query);
    }
    if (query.searchType === Contains.HOTEL) {
      router.push({
        pathname: `/hotels/${query.searchId}`,
        query: query
      });
    } else {
      router.push({
        pathname: "/search",
        query: query
      });
    }
  };
  const searchHeaderRender = useMemo(() => {
    if (navbarSearchBoxClick) {
      return <SearchHeader className={"!z-[12]"} searchData={searchData} setSearchData={setSearchData} isSearchClick={isSearchClick} isMainDim={viewState.dim} dimClickHandler={dimClickHandler} />;
    } else {
      return null;
    }
  }, [isSearchClick, navbarSearchBoxClick, searchData, viewState.dim]);
  const handlePrev = () => {
    setUserClickNaviBtn(true);
    if (swiper.activeIndex === 0) {
      swiper.slideTo(totalSlides - 1);
    } else {
      swiper.slidePrev();
    }
  };
  const handleNext = () => {
    setUserClickNaviBtn(true);
    if (swiper.activeIndex === totalSlides - 1) {
      swiper.slideTo(0);
    } else {
      swiper.slideNext();
    }
  };
  return <>
      {searchHeaderRender}
      <div onClick={dimClickHandler} className={`${viewState.dim ? "block" : "hidden"} fixed bottom-0 left-0 z-[11] h-[calc(100%-100px)] w-full bg-DimBlack-7`} />
      <div className="mb-[68px] h-[542px]" style={{
      backgroundColor: hexToRgbA(mainColor, 0.5)
    }}>
        <div className="relative mx-auto flex max-w-[1180px] flex-row">
          {/*좌측 검색 영역*/}
          <div className="mr-5 flex w-[480px] flex-col justify-center gap-2.5"
        // style={{ backgroundColor: hexToRgbA(mainColor, 0.2) }}
        >
            <div className="relative flex h-[156px] w-[420px] flex-col rounded-[38px] bg-white/20 p-2.5">
              {/*검색 영역*/}
              <div ref={ref} className="relative h-[64px] w-[400px] rounded-[50px] bg-white">
                <input id="schInput" className={`B_16_100_Medium relative w-full rounded-[50px] bg-transparent p-5 text-Gray-900 placeholder:opacity-50 ${isFocus.search ? "top-3 h-[auto] leading-[normal]" : "h-full"}`} type="text" style={{
                backgroundColor: "transparent"
              }} ref={searchTextRef} placeholder={recKeywordData} value={searchData.searchText || ""} onChange={searchTextHandle} maxLength={50} onFocus={() => {
                handleFocus("search");
              }} onClick={() => {
                if (isMobile) {
                  router.push({
                    pathname: "/search/mobile"
                  });
                }
              }} autoComplete="off" />
                {isFocus.search && searchData.query && <IcSearchClear width="18px" height="18px" className="absolute right-[34px] top-[31px] z-10 cursor-pointer" onClick={() => {
                setSearchBoxTextFlag(false);
                searchTextRef?.current?.focus();
              }} />}
                <label className={`C_12_100_Medium absolute left-5 ${getValueByDomain("text-Gray-300", "text-Gray-500")} top-[15px] transition-all duration-200 ease-[ease] tablet:hidden ${isFocus.search ? "opacity-100" : "opacity-0"}`}>
                  {t("searchbar.search.label")}
                </label>
                {isFocus.search && <FilterBox className={`tablet:hidden`} searchSearchBoxRef={ref} searchTextRef={searchTextRef} searchData={searchData} setSearchData={newData => {
                setSearchData(newData);
                if (!(searchData.query && searchMetaData)) {
                  setFilterBoxChanged(true);
                }
              }} searchBoxTextFlag={searchBoxTextFlag} searchBoxCloseHandler={() => {
                handleFocus("search", false);
                // setSearchBoxTextFlag(true);
              }} searchKeywords={webMain.keywords} searchMetaData={searchData.query && searchMetaData} filterBoxChangeCallback={filterBoxChangeCallback} />}
              </div>

              {/*일정 및 인원 영역*/}
              <div className="mt-2 flex h-[64px] w-[400px] flex-row items-center rounded-[50px] bg-white">
                {/*일정 영역*/}
                <div className="relative">
                  {isLoaded && <DatePickerBox closeOnScroll={isSearchScrollY} className={`flex h-[64px] w-[212px] items-center rounded-full bg-transparent pl-[20px] ${isFocus.date ? "pt-4 leading-normal" : ""} ${locale === "ko-KR" ? "B_16_100_Medium tablet:B_16_100_Regular" : "P_15_160_Medium"}`} dateFormat={t("search.datepicker.format")} searchData={searchData} setSearchData={setSearchData} openHandler={() => {
                  handleFocus("date");
                }} closeHandler={() => {
                  handleFocus("date", false);
                }} />}
                  <label className={`C_12_100_Medium absolute left-[20px] ${getValueByDomain("text-Gray-300", "text-Gray-500")} top-3 transition-all duration-200 ease-[ease] tablet:hidden ${isFocus.date ? "opacity-100" : "opacity-0"}`}>
                    {t("searchbar.days.label")}
                  </label>
                </div>

                <div className="h-[16px] w-[1px] bg-Gray-200"></div>

                {/*인원 영역*/}
                <div className="relative" ref={roomsBoxRef}>
                  <div className={`relative z-[5] flex h-[64px]
                        w-fit min-w-[120px] cursor-pointer items-center rounded-[100px] pl-4 text-Gray-800
                        tablet:h-[56px] tablet:w-[500px] tablet:focus:h-[56px] tablet:focus:pt-0
                        mobile:h-[48px] mobile:w-[calc(100%-16px)] mobile:pl-[24px] mobile:focus:h-[48px]
                        ${isFocus.room ? "pt-4 leading-normal" : ""} ${locale === "ko-KR" ? "B_16_100_Medium tablet:B_16_100_Regular" : "P_15_160_Medium"}`} onClick={() => {
                  setIsFocus(prevState => ({
                    ...initFocusState,
                    room: !prevState.room
                  }));
                }}>
                    <div className="P_15_120_Medium flex text-Gray-800">
                      <div className="flex items-center gap-0.5">
                        <Image src="/images/common/ic_room_ou_g80.svg" alt="roomIcon" width={16} height={16} />
                        <span className="mr-2 pt-[1px]">{selectRoomCnt}</span>
                      </div>
                      <div className="flex items-center gap-0.5">
                        <Image src="/images/common/ic_guest_ou_g80.svg" alt="roomIcon" width={16} height={16} />
                        <span className="pt-[1px]">{selectPeopleCnt}</span>
                      </div>
                    </div>
                    {/*{locale === "ko-KR"*/}
                    {/*  ? t("searchbar.people.txt", {*/}
                    {/*      n: { a: selectRoomCnt, b: selectPeopleCnt },*/}
                    {/*    })*/}
                    {/*  : `${selectRoomCnt} room${*/}
                    {/*      selectRoomCnt > 1 ? "s" : ""*/}
                    {/*    }, ${selectPeopleCnt} guest${selectPeopleCnt > 1 ? "s" : ""}`}*/}
                  </div>
                  <label className={`C_12_100_Medium absolute left-3 ${getValueByDomain("text-Gray-300", "text-Gray-500")} top-3 transition-all duration-200 ease-[ease] tablet:hidden ${isFocus.room ? " opacity-100" : "opacity-0"}`}>
                    {t("searchbar.people.label")}
                  </label>
                  {isFocus.room && <RoomCard roomsBoxRef={roomsBoxRef} isRoomBoxFocus={isFocus.room} defaultRoomInfo={searchData?.roomsInfo} roomBoxCloseHandler={() => {
                  handleFocus("room", false);
                }} roomSelectCompleteHandle={roomSelectCompleteHandle} mainOpen={true} />}
                </div>

                {/*검색 버튼*/}
                <div className="absolute right-4 flex h-[52px] w-[52px] cursor-pointer items-center justify-center rounded-full bg-Gray-900 p-[14px]" onClick={searchBtnHandler}>
                  <SearchBtnIcon w={20} h={20} />
                </div>
              </div>
            </div>

            {/*급상승 인기도시 영역*/}
            <div className="relative flex h-[292px] w-[420px] flex-col items-center rounded-[38px] border border-white/50" style={{
            backgroundColor: hexToRgbA(mainColor, 0.4)
          }}>
              {/* 타이틀 및 토글 버튼 영역 */}
              <div className="flex w-full items-center justify-between px-[34px] py-4 text-white">
                <div className="flex flex-col gap-1.5">
                  <span className="B_16_100_Bold">{rankData?.title}</span>
                  {/*<span className="C_12_100_Regular">*/}
                  {/*  {t("main.popular.date", {*/}
                  {/*    dates: dayjs(rankData?.stats_date).format(*/}
                  {/*      locale === "ko-KR" ? "YYYY. MM. DD" : "MMM D, YYYY",*/}
                  {/*    ),*/}
                  {/*  })}*/}
                  {/*</span>*/}
                </div>
                {locale === "ko-KR" && <div className="flex h-8 rounded-[50px] border border-white/50">
                    {[{
                  key: MAIN_BANNER_KEYWORD_TAB_TYPE.INTERNAL,
                  text: t("main.popular.toggle.internal")
                }, {
                  key: MAIN_BANNER_KEYWORD_TAB_TYPE.EXTERNAL,
                  text: t("main.popular.toggle.external")
                }].map((item, i) => <button key={i} className={`items-center rounded-[100px] ${toggleTab === item.key ? "SB_14_100_Bold bg-white px-4 text-Gray-700" : `SB_14_100_Regular bg-transparent text-white ${i === 0 ? "pl-4 pr-2" : "pl-2 pr-4"}`}`} onClick={() => setToggleTab(item.key)}>
                        {item.text}
                      </button>)}
                  </div>}
              </div>
              <hr className="w-[388px] text-white/15" />
              {/* 순위 리스트 영역 */}
              <div className="grid w-full grid-flow-col grid-rows-5 px-6 pt-2 text-white">
                {rankData?.keywords?.map(keyword => <div key={keyword.id} className="flex cursor-pointer items-center gap-1 px-3 py-2.5" onClick={() => {
                setSearchDateCookie(searchData.startDate, searchData.endDate);
                setSearchRoomsCookie(searchData.roomsInfo);
                setSearchHistory(keyword);
                setSearchData({
                  ...searchData,
                  query: keyword?.name,
                  searchId: keyword?.region_id,
                  searchType: keyword?.type,
                  searchText: keyword?.name
                });
                setFilterBoxChanged(true);
              }}>
                    <span className="B_16_100_Bold w-5 text-center">{keyword.rank}</span>
                    <span>
                      <Image src={`/images/common/${keyword.state === "up" ? "ic_arrow_up_fi_p50" : keyword.state === "down" ? "ic_arrow_down_fi_bl" : keyword.state === "new" ? "ic_arrow_new_fi_p50" : router.pathname === "/search/mobile" ? "ic_arrow_none_fi_wh_mobile" : "ic_arrow_none_fi_wh"}.png`} alt="rankStateIcon" width={14} height={14} />
                    </span>
                    <span>{keyword.name}</span>
                  </div>)}
              </div>
            </div>
          </div>

          {/*우측 빅배너*/}
          <div className="relative z-0 my-4 w-[680px]" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Swiper onSwiper={setSwiper} scrollbar={{
            hide: false,
            draggable: true,
            snapOnRelease: true,
            el: ".custom-scrollbar",
            dragSize: "auto"
          }} modules={[Scrollbar, Autoplay, Navigation]} autoplay={{
            delay: userClickNaviBtn ? 10000 : webMain?.banner?.big_banner[currentSlideIndex]?.type === BIG_BANNER_TYPE.BANNER_C ? 9000 : 5000,
            runCallbacks: true,
            disableOnInteraction: false
          }} slidesPerView={1} slidesPerGroup={1} spaceBetween={10} slideToClickedSlide={false} onSlideChange={swiper => {
            setCurrentSlideIndex(swiper.realIndex);
            videoRefs?.current?.forEach((ref, index) => resetVideo(index));
          }} onAutoplay={swiper => {
            setCurrentSlideIndex(swiper.realIndex);
          }} navigation={false}>
              {webMain?.banner?.big_banner.map((data, index) => <SwiperSlide key={index} className="flex cursor-pointer flex-row gap-3 overflow-hidden rounded-[12px]" onClick={() => {
              if (data.type !== BIG_BANNER_TYPE.BANNER_C) {
                slideCardClick(data);
              }
            }}>
                  {/*텍스트 레이어*/}
                  {data.type !== BIG_BANNER_TYPE.BANNER_C && <div className="absolute bottom-0 left-0 z-20 flex h-[182px] w-[280px] flex-col justify-center gap-2 rounded-tr-[36px] px-6 text-white" style={{
                backgroundColor: hexToRgbA(mainColor, 0.95)
              }}>
                      <div className="H3_26_120_Bold line-clamp-2">
                        {data?.title.replace(/\\n/g, "\n").split("\n").map((v, i) => <p key={i}>{v}</p>)}
                      </div>
                      <div className="SB_14_145_Regular line-clamp-2">
                        {data?.sub_title.replace(/\\n/g, "\n").split("\n").map((v, i) => <p key={i}>{v}</p>)}
                      </div>
                    </div>}

                  {/*이미지 및 동영상 레이어*/}
                  {data.contents.map((item, itemIndex) => <div key={itemIndex} className="relative" onClick={() => {
                if (data.type === BIG_BANNER_TYPE.BANNER_C) {
                  slideCardClick(item, BIG_BANNER_TYPE.BANNER_C);
                }
              }}>
                      <div className={`relative overflow-hidden ${data.type === BIG_BANNER_TYPE.BANNER_C ? "rounded-t-xl" : "rounded-xl"}`} onMouseEnter={() => {
                  if (item.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO) {
                    setPlaying(true);
                    setVideoIndex(itemIndex);
                    resetVideoError(itemIndex); // 마우스 호버 시 에러 상태 초기화
                    setHovering(true);
                    setLastHoveredIndex(itemIndex);
                  }
                }} onMouseLeave={() => {
                  if (item.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO) {
                    setHovering(false);
                    // if (data.type !== BIG_BANNER_TYPE.BANNER_C) {
                    //   setPlaying(false);
                    // }
                  }
                }}>
                        {item.type === BIG_BANNER_ITEM_CONTENTS_TYPE.IMAGE && <ImageFallback src={item.image_url} alt="BigBannerImg" width={data.type === BIG_BANNER_TYPE.BANNER_A ? 680 * 2 : 334 * 3} height={510 * 2} quality={90} className="h-[510px] object-cover object-center" />}
                        {item.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO && item.video_url && <DynamicReactPlayer ref={el => videoRefs.current[itemIndex] = el} key={item.video_url + itemIndex} url={item.video_url} playing={videoIndex === itemIndex && playing} // 비디오 인덱스와 현재 재생 중인 비디오 인덱스가 일치하면 재생
                  muted={true} className="relative z-10 object-cover object-center" loop={true} config={{}}
                  // light={item.thumbnail_url}
                  width={data.type === BIG_BANNER_TYPE.BANNER_C ? 220 : 334} height={data.type === BIG_BANNER_TYPE.BANNER_C ? 388 : 510} onReady={() => {
                    // setVideoLoading(prevState => {
                    //   const newState = [...prevState];
                    //   newState[itemIndex] = true;
                    //   return newState;
                    // });
                  }} onError={() => handleVideoError(itemIndex)} />}
                        {item.type === BIG_BANNER_ITEM_CONTENTS_TYPE.VIDEO &&
                  // (videoErrors[itemIndex] || !videoLoading[itemIndex]) && (
                  <ImageFallback src={item.thumbnail_url} alt="VideoErrorThumbnail" loading="eager" priority={true} quaility={50} width={data.type === BIG_BANNER_TYPE.BANNER_C ? 220 : 334} height={data.type === BIG_BANNER_TYPE.BANNER_C ? 388 : 510} className={`absolute top-0 object-cover object-center ${data.type === BIG_BANNER_TYPE.BANNER_C ? "h-[388px] w-[220px]" : "h-[510px] w-[334px]"}`} />
                  // )
                  }
                      </div>
                      {data.type === BIG_BANNER_TYPE.BANNER_C && <div className="flex h-[122px] w-full flex-col justify-between gap-2 rounded-b-[12px] bg-white/70 px-4 py-[18px]">
                          <div className="leading-4">
                            <span className="C_12_100_Medium text-Gray-500">
                              {item?.star_rating > 0 && `${item?.star_rating}${t("search.txt.star")}`}
                              {item?.star_rating > 0 && " · "}
                              {item?.region?.state && `${item?.region?.state}, `}
                              {item?.region?.country && item?.region?.country}
                            </span>
                            <span className="B_16_145_Bold line-clamp-1 text-Gray-800">
                              {item?.hotel_name}
                            </span>
                          </div>
                          <div className="leading-4">
                            <span className="SB_14_100_Bold text-[#FF375C]">
                              {item?.discount_rate}
                              {item?.discount_rate && `%`}
                            </span>
                            {item?.price && <div className="H5_20_100_Bold mt-1 text-Gray-900">
                                {locale === "en-US" && "$"}
                                {Commify(item?.price)}
                                <span className="C_12_100_Medium ml-0.5">
                                  {locale === "ko-KR" && "원~"}
                                  {locale === "en-US" && "~"}
                                </span>
                              </div>}
                          </div>
                        </div>}
                    </div>)}
                </SwiperSlide>)}
            </Swiper>
            {/* 커스텀 네비게이션 버튼 */}
            <div className={`swiper-button-prev !left-[-45px] !h-16 !w-16 transition-all delay-100 duration-500 ease-in after:!text-[0] ${isHovered ? "scale-100 opacity-100" : "scale-[0.8] opacity-0"}`} onClick={handlePrev}>
              <Image src="/images/common/ic_arrow_left_ou_b_g90_new.svg" alt="swiper-button-prev" width={64} height={64} />
            </div>
            <div className={`swiper-button-next !right-[-45px] !h-16 !w-16 transition-all delay-100 duration-500 ease-in after:!text-[0] ${isHovered ? "scale-100 opacity-100" : "scale-[0.8] opacity-0"}`} onClick={handleNext}>
              <Image src="/images/common/ic_arrow_right_ou_b_g90_new.svg" alt="swiper-button-next" width={64} height={64} />
            </div>
          </div>
          <div className="custom-scrollbar absolute !bottom-[-28px] z-0 h-1 w-full bg-black/5"></div>
        </div>
      </div>
    </>;
});
export default SearchBoxV2;