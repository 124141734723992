import SlideCard from "./SlideCard";
import styled from "styled-components";
import Title01 from "../common/Title01";
import styles from "../Main.module.css";
import dynamic from "next/dynamic";
import { gtagEvent } from "@modules/lib/gtm";
const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false
});
export default function ListA({
  info
}) {
  return <section className={`${styles.mGroup4} mGroup4 mb-[128px] pt-[40px] pb-[60px] mobile:mb-[40px] mobile:pt-[20px] mobile:pb-[33px]`}>
      {info.title && <Title01>{info.title}</Title01>}
      {info.items && <>
          <MediaQuery minWidth={1025}>
            <div className="listTumb tp2">
              <div className="swiper-container">
                <ul className="m-[-22px_auto_0] flex max-w-[1180px] flex-wrap overflow-hidden">
                  {info.items?.map((item, index) => <li key={index} className={`float-none mt-[24px] ml-0 w-[calc(25.42%-20px)] 
                              ${(index + 1) % 4 === 0 ? "mr-0" : "mr-[20px]"}`}>
                      <SlideCard item={item} />
                    </li>)}
                </ul>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <ListTumb className="tablet:m-0 tablet:overflow-x-auto tablet:overflow-y-hidden tablet:whitespace-nowrap tablet:scrollbar-hide" onTouchStart={() => {
          gtagEvent("sliderSwipe_main_listA", {});
        }}>
              <ul className="m-[-22px_auto_0] flex gap-[20px] tablet:m-0 tablet:my-0 tablet:mx-[24px] tablet:inline-flex tablet:w-auto mobile:mx-[16px] mobile:gap-[12px]">
                {info.items?.map((item, index) => <li key={index} className="tablet:w-[280px] mobile:w-[154px]">
                    <SlideCard item={item} />
                  </li>)}
              </ul>
            </ListTumb>
          </MediaQuery>
        </>}
    </section>;
}
const ListTumb = styled.div.withConfig({
  displayName: "ListA__ListTumb",
  componentId: "sc-50e562-0"
})(["::-webkit-scrollbar{", "}"], {
  "height": "0"
});